/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import { PlanListing } from '../components/Plans/PlanListing';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ReactComponent as SearchIcon } from '../assets/imgs/search-normal.svg';
import { black, porcelain, white } from '../constants/colors';
import { formatToTableDate, sortPlans, validateEmail } from '../utils/utils';
import { toast } from 'react-toastify';
import httpClient from '../config/http.service';
import { store } from '../Store';
import { ManageCompanyModal } from '../modals/ManageCompanyModal';
import useDebounce from '../utils/hooks/useDebounce';
import { DeleteCompanyModal } from '../modals/DeleteCompany';
import { pageSize } from '../utils/constants';

export const Dashboard = () => {
  const { state } = useContext(store);
  const [companies, setCompanies] = useState(null);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstTimeUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [update, setUpdate] = useState(false);
  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [companyPhotoForPreview, setCompanyPhotoForPreview] = useState(null);
  const [typeForAction, setTypeForAction] = useState('');
  const [company, setCompany] = useState(null);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [selectedSortDirection, setSelectedSortDirection] = useState('asc');
  const [selectedSortOption, setSelectedSortOption] = useState('title');
  const [companyPhotoDetails, setCompanyPhotoDetails] = useState(null);

  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1);
  const [renderAfterPageChange, setRenderAfterPageChange] = useState(true)

  useEffect(() => {
    if (searchTerm !== '') {
      getCompanies(searchTerm)
      setRenderAfterPageChange(false)
      setPageNumber(1)
    } else {
      setRenderAfterPageChange(true)
      getCompanies()
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (state?.data?.user?.company && renderAfterPageChange) {
      getCompanies();
    }
  }, [state?.data, update, pageNumber]);

  useEffect(() => {
    if (company?._id && company.hasAvatar) getCompanyPhoto(company._id, true);
  }, [company?._id]);

  const handleCompanyCheckbox = (e) => {
    setCompany({ ...company, fixedDetails: e.target.checked });
  };

  const handleCompanyDetails = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const removeImage = (e) => {
    e.preventDefault();
    if (company?._id) {
      httpClient
        .patch('api/company/remove-avatar', {
          _id: company._id,
        })
        .then((res) => {
          setCompanyPhotoForPreview(null);
          setCompanyPhoto(null);
          setUpdate(!update);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      // setCompanyPhoto(null);
      setCompanyPhotoForPreview(null);
    }
  };

  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    if (company?._id) {
      const newFile = new FormData();
      const newName = new File([file], company._id, { type: file.type });
      newFile.append('image', newName);
      newFile.append('company_id', company._id);
      httpClient
        .post('api/company/add-avatar', newFile)
        .then((res) => {
          setCompanyPhotoForPreview(URL.createObjectURL(file));
          setCompanyPhoto(file);
          setUpdate(!update);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      setCompanyPhoto(file);
      setCompanyPhotoForPreview(URL.createObjectURL(file));
    }
  };

  const handleAdd = () => {
    let newFile = new FormData();
    const newCompany = {
      name: company.title,
      website: company.website,
    };

    if (companyPhoto) {
      const newName = new File([companyPhotoDetails], 'companyimage', {
        type: companyPhotoDetails.type,
      });
      newFile.append('image', newName);
      newFile.append('company', JSON.stringify(newCompany));
    } else {
      newFile.append('image', {});
      newFile.append('company', JSON.stringify(newCompany));
    }

    httpClient
      .post('api/company/create', newFile)
      .then(() => {
        toast.success(`Successfully added company!`);
        setOpenManageModal(false);
        setCompany(null);
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const handleUpdateCompany = () => {
    httpClient
      .patch('api/company/admin-update', {
        _id: company._id,
        name: company.title,
        website: company.website,
        mainContactName: company.mainContactName,
        mainContactEmail: company.mainContactEmail,
        fixedDetails: company.fixedDetails,
      })
      .then(() => {
        toast.success(`Successfully updated company!`);
        setOpenManageModal(false);
        setCompany(null);
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const handleDeleteCompany = () => {
    httpClient
      .delete('api/company/delete', {
        data: {
          _id: company._id,
        },
      })
      .then(() => {
        toast.success(`Successfully deleted company!`);
        setDeleteCompanyModal(false);
        setOpenManageModal(false);
        setCompany(null);
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const getCompanyPhoto = (companyId, preview = false) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`api/file/get/${companyId}?width=64&height=64`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);

          if (preview) {
            setCompanyPhotoForPreview(imageUrl);
          }
          resolve(imageUrl);
        })
        .catch((err) => {
          if (err?.response?.data?.code !== 400)
            toast.info(err?.response?.data?.message);
          reject(err);
        });
    });
  };

  const getCompanies = async (name = '') => {
    try {
      const locPageNumber = !name ? pageNumber : 1;
      const res = await httpClient.get(`api/company/all?pageSize=${pageSize}&page=${locPageNumber}&name=${name}`);
      const valueToString = res.data.companies || [];
      setTotalPages(res.data.totalPages ?? 0)
      let newPlansWithoutLogo = [];

      for (let el of valueToString) {
        let obj = {};
        obj._id = el._id + '';
        obj.website = el.website;
        obj.title = el.name;
        obj.renewalDate = formatToTableDate(el.renewalDate);
        obj.totalUsers = el.totalUsers;
        obj.totalTeams = el.teams?.length || 0;
        obj.fixedDetails = el.fixedDetails;
        obj.hasAvatar = el.hasAvatar;
        newPlansWithoutLogo.push(obj);
      }
      setCompanies(newPlansWithoutLogo);

      const newPlans = []

      for (let el of newPlansWithoutLogo){
        const obj = {...el, logo: ''}
        if (el.hasAvatar) {
          obj.logo = await getCompanyPhoto(el._id);
        }
        newPlans.push(obj)
      }
      setCompanies(newPlans)

    } catch (err) {
      toast.info(err?.response?.data?.message);
    }
  };

  const handleSearch = (value) => setSearchTerm(value);

  const onClickRow = (obj, index) => {
    setSelectedRow(obj);
  };

  const handleSortChange = (item) => {
    setSelectedSortOption(item);
    setSelectedSortDirection(selectedSortDirection === 'asc' ? 'desc' : 'asc');
    const sortedArray = sortPlans(
      item,
      companies,
      selectedSortDirection === 'asc' ? 'desc' : 'asc'
    );
    setCompanies(sortedArray);
  };
  return (
    <Box sx={styles.main}>
      {!firstTimeUser ? (
        <Box sx={{ padding: '23px 16px' }}>
          <Container maxWidth="xxl">
            <Grid container sx={styles.tabs}>
              <Grid item sx={styles.tabsItem}>
                <Typography sx={styles.title}>Companies</Typography>
              </Grid>
              <Grid item sx={styles.header_btns}>
                <Input
                  id="search"
                  placeholder="Search by company name"
                  sx={styles.search_input}
                  onChange={(event) => handleSearch(event.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ marginLeft: '17.5px' }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  style={styles.add_btn}
                  onClick={() => {
                    setTypeForAction('add');
                    setOpenManageModal(true);
                  }}
                >
                  Add Company
                  <AddCircleOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          </Container>
          <PlanListing
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onClickRow={onClickRow}
            plans={companies}
            setPlans={setCompanies}
            update={update}
            setUpdate={setUpdate}
            handleSortChange={handleSortChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
            handleManage={(e, single) => {
              setCompany(single);
              setTypeForAction('manage');
              setOpenManageModal(true);
            }}
            page={pageNumber}
            setPage={setPageNumber}
            totalPages={totalPages}
          />
          <ManageCompanyModal
            handleCheckbox={handleCompanyCheckbox}
            handleCompanyDetails={handleCompanyDetails}
            companyPhoto={companyPhoto}
            setCompanyPhoto={setCompanyPhoto}
            companyPhotoDetails={companyPhotoDetails}
            setCompanyPhotoDetails={setCompanyPhotoDetails}
            companyPhotoForPreview={companyPhotoForPreview}
            handleFileUpload={handleFileUpload}
            company={company}
            handleUpdate={handleUpdateCompany}
            // handleDelete={handleDeleteCompany}
            handleDelete={() => setDeleteCompanyModal(true)}
            open={openManageModal}
            removeImage={removeImage}
            handleClose={() => {
              setCompany(null);
              setCompanyPhotoForPreview(null);
              setCompanyPhoto(null);
              setOpenManageModal(false);
            }}
            type={typeForAction}
            handleAdd={handleAdd}
          />
          <DeleteCompanyModal
            open={deleteCompanyModal}
            handleClose={() => setDeleteCompanyModal(false)}
            handleDelete={handleDeleteCompany}
          />
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};

const styles = {
  main: {
    backgroundColor: `${porcelain} !important`,
    minHeight: '100vh !important',
  },
  header: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexWrap: 'wrap !important',
    alignItems: 'center',
    marginBottom: '25px',
  },
  search_input: {
    backgroundColor: white,
    width: '250px',
    height: '40px',
    border: '1px solid #F4F4F4',
    borderRadius: '32px',
    fontSize: '14px',
    marginRight: '12px',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: '20px',
    marginBottom: '20px',
    padding: '9px',
    '@media screen and (min-width: 500px)': {
      justifyContent: 'space-between',
    },
  },
  tabsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    '@media screen and (min-width: 500px)': {
      flexDirection: 'row',
    },
    '@media screen and (min-width: 1150px)': {
      marginBottom: '0px',
    },
  },
  title: {
    color: black,
    fontSize: '18px',
    fontFamily: 'SoehneBold',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    background: white,
    borderRadius: '8px',
    padding: '10px 14px',
    gap: '8px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '20px',
    },
  },
  header_btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginLeft: '20px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '0',
    },
  },
  menu: {
    borderRadius: 8,
  },
  add_btn: {
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '24px',
    height: '40px',
    padding: '10px 20px',
    shadowOffset: { width: 0, height: 0 },
    shadowColor: 'transparent',
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    fontFamily: 'SoehneBold',
  },
};

import { toast } from 'react-toastify';
import { ReactComponent as SuccessIcon } from '../../assets/imgs/toast-success.svg';
import { ReactComponent as CloseIcon } from '../../assets/imgs/toast-close.svg';
import { ReactComponent as CloseIconLight } from '../../assets/imgs/toast-close-light.svg';

const CustomCloseButton = () => {
  return <CloseIcon className="custom-close-icon-dark" />;
};

const CustomCloseButtonLight = () => {
  return <CloseIconLight className="custom-close-icon-light" />;
};

const CustomMessage = ({ children, theme }) => (
  <span
    style={{
      color: theme === 'dark' ? 'white' : 'black',
      fontFamily: 'InterRegular',
      fontSize: '14px',
    }}
  >
    {children}
  </span>
);

export const showNotification = (
  type,
  message,
  theme = 'dark',
  position = 'bottom-center'
) => {
  switch (type) {
    case 'success':
      toast.success(<CustomMessage theme={theme}>{message}</CustomMessage>, {
        className: 'toast-container',
        icon: <SuccessIcon />,
        closeButton:
          theme === 'dark' ? <CustomCloseButton /> : <CustomCloseButtonLight />,
        theme: theme,
        position: position,
      });
      break;
    case 'info':
      toast.info(message, {
        className: 'toast-container',
        closeButton:
          theme === 'dark' ? <CustomCloseButton /> : <CustomCloseButtonLight />,
        theme: theme,
        position: position,
      });
      break;
    case 'error':
      toast.error(message, {
        className: 'toast-container',
        closeButton:
          theme === 'dark' ? <CustomCloseButton /> : <CustomCloseButtonLight />,
        theme: theme,
        position: position,
      });
      break;
    // Add more cases for different types if needed
    default:
      toast(message);
  }
};

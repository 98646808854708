import { foam, funGreen } from '../constants/colors';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ContactPageIcon from '@mui/icons-material/ContactPage';

export const sortDirections = [
  { name: 'Ascending', key: 'asc' },
  { name: 'Descending', key: 'desc' },
];

export const sortPinned = (a, b) => {
  if (a.pinned && !b.pinned) {
    return -1;
  }
  if (!a.pinned && b.pinned) {
    return 1;
  }
  return 0;
};

export const companiesTableHeaders = [
  { key: 'title', title: 'Company Name', minWidth: '200px' },
  {
    key: 'totalTeams',
    title: 'Total teams',
    minWidth: '180px',
    align: 'center',
  },
  {
    key: 'totalUsers',
    title: 'Total Users',
    minWidth: '180px',
    align: 'center',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const usersTableHeaders = [
  { key: 'username', title: 'User Name', minWidth: '200px' },
  {
    key: 'companyName',
    title: 'Company Name',
    minWidth: '180px',
    align: 'left',
  },
  {
    key: 'teamname',
    title: 'Team Name',
    minWidth: '140px',
    align: 'left',
  },
  {
    key: 'tier',
    title: 'Tier',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'numberOfPlans',
    title: 'Number of Live Plans',
    minWidth: '180px',
    align: 'center',
  },
  {
    key: 'lastLogin',
    title: 'Last Login',
    minWidth: '140px',
    align: 'left',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const paidUsersTableHeaders = [
  {
    key: 'companyName',
    title: 'Company Name',
    minWidth: '125px',
    align: 'left',
  },
  {
    key: 'teamname',
    title: 'Team Name',
    minWidth: '110px',
    align: 'left',
  },
  { key: 'username', title: 'User Name', minWidth: '110px' },
  {
    key: 'jobTitle',
    title: 'Job Title',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'numberOfPlans',
    title: 'Number of Live Plans',
    minWidth: '100px',
    align: 'center',
  },
  {
    key: 'lastLogin',
    title: 'Last Login',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'renewalDate',
    title: 'Renewal Date',
    minWidth: '110px',
    align: 'left',
  },
  {
    key: 'mainContactName',
    title: 'Main point of contact',
    minWidth: '100px',
    align: 'left',
  },
  {
    key: 'mainContactEmail',
    title: 'Main point of contact email',
    minWidth: '100px',
    align: 'left',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];
export const invitedUsersTableHeaders = [
  {
    key: 'companyName',
    title: 'Company Name',
    minWidth: '125px',
    align: 'left',
  },
  { key: 'username', title: 'User Name', minWidth: '110px' },
  {
    key: 'jobTitle',
    title: 'Job Title',
    minWidth: '100px',
    align: 'left',
  },
  { key: 'paid', title: 'User type', minWidth: '60px', align: 'center' },
  { key: 'tokenToSend', title: 'User token', minWidth: '60px', align: 'center' },
  { key: 'resendInvite', title: 'Actions', minWidth: '60px', align: 'center' },
];
export const freeUsersTableHeaders = [
  {
    key: 'companyName',
    title: 'Company Name',
    minWidth: '125px',
    align: 'left',
  },
  { key: 'username', title: 'User Name', minWidth: '130px' },
  {
    key: 'email',
    title: 'User Email',
    minWidth: '130px',
    align: 'left',
  },
  {
    key: 'jobTitle',
    title: 'Job Title',
    minWidth: '60px',
    align: 'left',
  },
  {
    key: 'numberOfPlans',
    title: 'Number of Live Plans',
    minWidth: '150px',
    align: 'center',
  },
  {
    key: 'lastLogin',
    title: 'Last Login',
    minWidth: '100px',
    align: 'left',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const adminUsersTableHeaders = [
  {
    key: 'firstName',
    title: 'First Name',
    minWidth: '125px',
    align: 'left',
  },
  {
    key: 'lastName',
    title: 'Last Name',
    minWidth: '125px',
    align: 'left',
  },
  {
    key: 'email',
    title: 'User Email',
    minWidth: '130px',
    align: 'left',
  },
  { key: 'actions', title: 'Actions', minWidth: '60px', align: 'center' },
];

export const planStatusOptions = [
  { name: 'Select status', value: '' },
  {
    name: 'To be booked',
    value: 'to_be_booked',
    color: '#FEF2E6',
    fontColor: '#844302',
  },
  { name: 'To do', value: 'to_do', color: '#FFF9E5', fontColor: '#604804' },
  { name: 'Ongoing', value: 'ongoing', color: '#EDF6F7', fontColor: '#074D54' },
  { name: 'Booked', value: 'booked', color: '#F3EBFF', fontColor: '#330471' },
  {
    name: 'Completed',
    value: 'completed',
    color: foam,
    fontColor: funGreen,
  },
];

export const planTagOptions = [
  { name: 'Select Tag', value: '' },
  { name: 'Vendor Internal', value: 'vendor_internal' },
  { name: 'Customer Internal', value: 'customer_internal' },
  { name: 'In-Person Meeting', value: 'in_person_meeting' },
  { name: 'Zoom/Teams meeting', value: 'zoom_teams_meeting' },
  { name: 'Phone call', value: 'phone_call' },
  { name: 'Email', value: 'email' },
];

export const planDurationOptions = [
  { name: 'Duration', value: '' },
  { name: '15 minutes', value: '15m' },
  { name: '30 minutes', value: '30m' },
  { name: '1 hour', value: '1h' },
  { name: '2 hours', value: '2h' },
  { name: '4 hours', value: '4h' },
  { name: '1 day', value: '1d' },
  { name: 'Custom', value: 'custom' },
];

export const durationOptions = [
  { name: 'Minutes', value: 'm' },
  { name: 'Hours', value: 'h' },
  { name: 'Days', value: 'd' },
];

export const currencyOptions = [
  { name: '$', value: 'dollar' },
  { name: '€', value: 'euro' },
  { name: '£', value: 'pound' },
];

export const mockUsers = [
  { firstName: 'Pera', lastName: 'Peric', rights: 'Can View' },
  { firstName: 'Vukasin', lastName: 'Macura', rights: 'Can View' },
];

export const mockSteps = [
  { label: '🔬 DISCOVER', range: 'Jan 05 -  Jan 20', icon: RocketLaunchIcon },
  { label: '🚀 ENGAGE', range: 'Jan 24 -  Feb 27', icon: HandshakeIcon },
  { label: '🤝 DECIDE', range: 'Mar 12  -  Apr 21', icon: ContactPageIcon },
  { label: '📄 CONTRACT', range: 'Apr 30 -  May 10', icon: RocketLaunchIcon },
  { label: 'Stage 5', range: 'Apr 30 -  May 10', icon: HandshakeIcon },
  { label: 'Stage 6', range: 'Apr 30 -  May 10', icon: ContactPageIcon },
  { label: 'Stage 7', range: 'Apr 30 -  May 10', icon: RocketLaunchIcon },
  { label: 'Stage 8', range: 'Apr 30 -  May 10', icon: HandshakeIcon },
  { label: '', range: '', icon: HandshakeIcon },
];

export const pageSize = 8;
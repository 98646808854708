import React, { useState } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { black, mercury, shark, wildSand } from '../constants/colors';
import httpClient from '../config/http.service';
import { toast } from 'react-toastify';
import InputWithLabel from '../components/common/InputWithLabel';

export const CreateNewTemplate = ({
  open,
  handleClose,
  setShowCreateNewTemplate,
  updateTemplates,
  setUpdateTemplates,
}) => {
  const [data, setData] = useState({
    name: '',
    description: '',
  });

  const createTemplate = () => {
    httpClient
      .post('api/template/new', {
        name: data?.name,
        description: data?.description,
        defaultTemplate: false,
      })
      .then((response) => {
        const url = `${process.env.REACT_APP_WEB}template/add/${response.data._id}`;
        setShowCreateNewTemplate(false);
        setUpdateTemplates(!updateTemplates);
        window.open(url, '_blank');
      })
      .catch((err) => {
        toast.info(err?.response?.data?.message);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Create New Template
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <InputWithLabel
        label="Template name*"
        name="name"
        value={data.name}
        placeholder="Template name"
        handleChange={(e) => setData({ ...data, name: e.target.value })}
      />

      <InputWithLabel
        label="Template description*"
        name="description"
        multiline={true}
        rows="2"
        value={data.description}
        placeholder="Enter a description to easily identify template"
        styles={{ marginBottom: '5px' }}
        handleChange={(e) => setData({ ...data, description: e.target.value })}
      />
      <Box sx={styles.btns}>
        <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={styles.template}
          onClick={createTemplate}
          disabled={data.description === '' || data.name === ''}
        >
          Create Template
        </Button>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
  },
};

import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  alabaster,
  black,
  bronze,
  earlyDawn,
  foam,
  funGreen,
  gallery,
  primary,
  warning,
  white,
} from '../constants/colors';
import { toast } from 'react-toastify';
import { getInitials, validateHttpLink } from '../utils/utils';
import { store } from '../Store';
import httpClient from '../config/http.service';
import { UpdateNameModal } from '../components/Settings/UpdateNameModal';
import { ChangePasswordModal } from '../components/Settings/ChangePasswordModal';
import withDashboardHeader from '../hoc/withDashboardHeader';
import { useNavigate } from 'react-router-dom';

const ProfileSettings = () => {
  const navigate = useNavigate('');
  const { state, dispatch } = useContext(store);
  const [user, setUser] = useState();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdateName, setShowUpdateName] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (state?.data?.user) {
      setUser(state?.data?.user);
    }
  }, [state?.data, update]);

  useEffect(() => {
    if (state.data.user.hasAvatar) getUserPhoto(state.data.user._id);
  }, [state?.data?.userImage]);

  const getUserPhoto = async (userId) => {
    if (userId) {
      httpClient
        .get(`api/file/get/${userId}?width=64&height=64`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setProfilePhoto(imageUrl);
        })
        .catch((err) => {
          if (err?.response?.data?.code !== 400)
            toast.info(err?.response?.data?.message);
        });
    }
  };

  const updateName = () => {
    if (user.linkedin !== '') {
      if (validateHttpLink(user.linkedin)) {
        httpClient
          .patch(`api/user/update`, {
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            linkedin: user.linkedin,
          })
          .then((res) => {
            setUser(res.data);
            dispatch({
              type: 'SET_DATA',
              payload: {
                isAuthenticated: true,
                user: res.data,
                accessToken: state.data.accessToken,
                userImage: state.data.userImage,
              },
            });
            const rememberMe = localStorage.getItem('rememberMe');
            if (rememberMe == 'true') {
              localStorage.setItem('user', JSON.stringify(res.data));
            } else {
              sessionStorage.setItem('user', JSON.stringify(res.data));
            }
            toast.success('Successfully updated details!');
            setShowUpdateName(false);
          })
          .catch((err) => {
            toast.info(err?.response?.data?.message);
          });
      } else {
        toast.info('Linkedin profile link is not valid!');
      }
    } else {
      httpClient
        .patch(`api/user/update`, {
          firstName: user.firstName,
          lastName: user.lastName,
          jobTitle: user.jobTitle,
          linkedin: user.linkedin,
        })
        .then((res) => {
          dispatch({
            type: 'SET_DATA',
            payload: {
              isAuthenticated: true,
              user: res.data,
              accessToken: state.data.accessToken,
              userImage: state.data.userImage,
            },
          });
          toast.success('Successfully updated details!');
          setUser(res.data);
          setShowUpdateName(false);
        })
        .catch((err) => {
          toast.info(err?.response?.data?.message);
        });
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      if (user.linkedin !== '') {
        if (validateHttpLink(user.linkedin)) {
          httpClient
            .patch(`api/user/update`, {
              firstName: user.firstName,
              lastName: user.lastName,
              jobTitle: user.jobTitle,
              linkedin: user.linkedin,
            })
            .then((res) => {
              setUser(res.data);
              dispatch({
                type: 'SET_DATA',
                payload: {
                  isAuthenticated: true,
                  user: res.data,
                  accessToken: state.data.accessToken,
                  userImage: state.data.userImage,
                },
              });
              const rememberMe = localStorage.getItem('rememberMe');
              if (rememberMe == 'true') {
                localStorage.setItem('user', JSON.stringify(res.data));
              } else {
                sessionStorage.setItem('user', JSON.stringify(res.data));
              }
              toast.success('Successfully updated details!');
              setShowUpdateName(false);
            })
            .catch((err) => {
              toast.info(err?.response?.data?.message);
            });
        } else {
          toast.info('Linkedin profile link is not valid!');
        }
      } else {
        httpClient
          .patch(`api/user/update`, {
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            linkedin: user.linkedin,
          })
          .then((res) => {
            dispatch({
              type: 'SET_DATA',
              payload: {
                isAuthenticated: true,
                user: res.data,
                accessToken: state.data.accessToken,
                userImage: state.data.userImage,
              },
            });
            toast.success('Successfully updated details!');
            setUser(res.data);
            setShowUpdateName(false);
          })
          .catch((err) => {
            toast.info(err?.response?.data?.message);
          });
      }
    }
  };

  const updatePassword = (e) => {
    e.preventDefault();

    if (oldPassword === '') {
      toast.error('Please enter your old password.');
      return;
    }
    if (newPassword === '') {
      toast.error('Please enter a new password.');
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.info('New password and confirm password do not match.');
      return;
    }
    httpClient
      .patch(`api/user/change-password`, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      })
      .then((res) => {
        // Perform your password change logic here
        toast.success('Successfully updated password!');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setShowChangePassword(false);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const handleEnterPassword = (event) => {
    if (event.key === 'Enter') {
      if (oldPassword === '') {
        toast.error('Please enter your old password.');
        return;
      }
      if (newPassword === '') {
        toast.error('Please enter a new password.');
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.info('New password and confirm password do not match.');
        return;
      }
      httpClient
        .patch(`api/user/change-password`, {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        })
        .then((res) => {
          // Perform your password change logic here
          toast.success('Successfully updated password!');
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setShowChangePassword(false);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    }
  };

  const handleChangeName = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleChangePassword = (e, name) => {
    if (name === 'old') setOldPassword(e.target.value);
    else if (name === 'new') setNewPassword(e.target.value);
    else setConfirmPassword(e.target.value);
  };

  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    const newFile = new FormData();
    const newName = new File([file], 'file', { type: file.type });
    newFile.append('image', newName);
    newFile.append('user_id', user._id);
    httpClient
      .post('api/user/add-avatar', newFile)
      .then((res) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: res.data,
            accessToken: state.data.accessToken,
            userImage: URL.createObjectURL(file),
          },
        });
        setUser(res.data);
        const newUser = { ...res.data };
        newUser.hasAvatar = true;
        const rememberMe = localStorage.getItem('rememberMe');
        if (rememberMe == 'true') {
          localStorage.setItem('user', JSON.stringify(res.data));
        } else {
          sessionStorage.setItem('user', JSON.stringify(res.data));
        }
        setProfilePhoto(URL.createObjectURL(file));
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const removeImage = (e) => {
    e.preventDefault();
    if (user?._id) {
      httpClient
        .patch('api/user/remove-avatar', {
          _id: user._id,
        })
        .then((res) => {
          dispatch({
            type: 'SET_DATA',
            payload: {
              isAuthenticated: true,
              user: res.data,
              accessToken: state.data.accessToken,
              userImage: null,
            },
          });
          const newUser = { ...res.data };
          newUser.hasAvatar = false;
          const rememberMe = localStorage.getItem('rememberMe');
          if (rememberMe == 'true') {
            localStorage.setItem('user', JSON.stringify(res.data));
          } else {
            sessionStorage.setItem('user', JSON.stringify(res.data));
          }
          setProfilePhoto(null);
          setUpdate(!update);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      setProfilePhoto(null);
    }
  };

  const redirectToLink = () => {
    window.location.href = user?.linkedin ?? 'https://linkedin.com';
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Profile Settings</Typography>
      <Box sx={styles.actionBtns}>
        <Box sx={styles.avatarBox}>
          {profilePhoto ? (
            <Avatar sx={{ ...styles.avatar }} src={profilePhoto} />
          ) : (
            <Avatar sx={styles.avatar}>
              {getInitials(user?.firstName, user?.lastName)}
            </Avatar>
          )}
          {!profilePhoto ? (
            <Button variant="outlined" component="label" sx={styles.uploadBtn}>
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => handleFileUpload(e)}
              />
              Upload profile photo
            </Button>
          ) : (
            <Button
              variant="outlined"
              component="label"
              sx={styles.removeBtn}
              onClick={removeImage}
            >
              Remove
            </Button>
          )}
        </Box>
        <Box style={styles.btns}>
          <Button
            variant="outlined"
            component="label"
            sx={styles.goBack}
            onClick={() => navigate('/dashboard')}
          >
            Go back
          </Button>
        </Box>
      </Box>

      <Typography sx={styles.subtitle}>Personal details: </Typography>
      <Typography sx={styles.label}>Name </Typography>
      <Typography sx={styles.value}>
        {user?.firstName ?? 'Manuel'} {user?.lastName ?? 'Snr'}
      </Typography>
      <Typography sx={styles.label}>Job title: </Typography>
      <Typography sx={styles.value}>{user?.jobTitle ?? 'CEO'}</Typography>
      <Typography sx={styles.label}>Linkedin profile: </Typography>
      <Typography sx={styles.value}>
        <span onClick={redirectToLink} sx={styles.value}>
          {user?.linkedin ?? ''}
        </span>
      </Typography>
      <Typography
        sx={styles.updateButton}
        onClick={() => setShowUpdateName(true)}
      >
        Update profile details
      </Typography>
      <Typography sx={styles.label}>User email: </Typography>
      <Typography sx={styles.value}>{user?.email ?? ''}</Typography>
      <Typography sx={styles.label}>Password</Typography>
      <Typography
        sx={styles.updateButton}
        onClick={() => setShowChangePassword(true)}
      >
        Update password
      </Typography>
      <UpdateNameModal
        open={showUpdateName}
        handleClose={() => setShowUpdateName(false)}
        updateName={updateName}
        handleChange={handleChangeName}
        handleEnter={handleEnter}
        user={user}
      />
      <ChangePasswordModal
        open={showChangePassword}
        handleClose={() => setShowChangePassword(false)}
        oldPassword={oldPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        updatePassword={updatePassword}
        handleChange={handleChangePassword}
        handleEnterPassword={handleEnterPassword}
      />
    </Box>
  );
};

export default withDashboardHeader(ProfileSettings);

const styles = {
  container: {
    padding: '30px',
  },
  title: {
    fontWeight: 700,
    color: black,
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '15px',
    '@media screen and (min-width: 900px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '0',
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarBox: {
    display: 'flex',
    marginTop: '26px',
  },
  avatar: {
    height: '64px',
    width: '64px',
    border: `1px solid ${gallery}`,
    backgroundColor: alabaster,
    color: black,
    fontWeight: 700,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
  },
  companyLogo: {
    height: '64px',
    width: '64px',
    border: `1px solid ${gallery}`,
    backgroundColor: alabaster,
    color: black,
    fontWeight: 700,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontSize: '12px',
    textAlign: 'center',
  },
  uploadBtn: {
    marginLeft: '30px',
    padding: '8px 16px',
    border: ` 1px solid ${primary}`,
    borderRadius: '59px',
    height: '40px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
  },
  removeBtn: {
    padding: '8px 16px',
    borderRadius: '59px',
    height: '40px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    backgroundColor: warning,
    color: white,
    border: 'none',
    marginLeft: '30px',
    '&:hover': {
      color: warning,
      borderColor: warning,
      backgroundColor: white,
    },
  },
  subtitle: {
    marginTop: '24px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color: primary,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
  },
  label: {
    marginTop: '16px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: black,
  },
  value: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  password: {
    marginTop: '8px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: black,
  },
  updateButton: {
    marginTop: '12px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    color: primary,
    cursor: 'pointer',
  },
  verified: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: funGreen,
    backgroundColor: foam,
    marginLeft: '8px',
  },
  unverified: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: bronze,
    backgroundColor: earlyDawn,
    marginLeft: '8px',
  },
  verifyBox: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewAdmin: {
    padding: '8px 16px',
    borderRadius: '59px',
    height: '40px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    backgroundColor: primary,
    color: white,
    border: 'none',
    '&:hover': {
      color: primary,
      borderColor: primary,
      backgroundColor: white,
    },
  },
  goBack: {
    marginLeft: '30px',
    padding: '8px 16px',
    borderRadius: '59px',
    height: '40px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    backgroundColor: white,
    color: primary,
    borderColor: primary,
    '&:hover': {
      color: white,
      borderColor: white,
      backgroundColor: primary,
    },
  },
};

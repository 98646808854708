/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Login } from './pages/login';
import { NotFound } from './pages/notFound';
import { primary } from './constants/colors';
import uppershape from './assets/imgs/uppershape.svg';
import lowershape from './assets/imgs/lowershape.svg';
import './index.css';
import { store } from './Store';
import { pathToRegexp } from 'path-to-regexp';
import { AdminPanelSidebar } from './pages/admin/Sidebar/sidebar';
import ProfileSettings from './pages/profileSettings';
import { CreatePassword } from './pages/createPassword';
import { ResetPassword } from './pages/resetPassword';

const theme = createTheme({
  palette: { primary: { main: primary } },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontFamily: `"Copyright Klim Type Foundry", sans-serif`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: `"Copyright Klim Type Foundry", sans-serif`,
          lineHeight: '20px',
          fontWeight: '400',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: `"Copyright Klim Type Foundry", sans-serif`,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.MuiSelected': {
            backgroundColor: '#00818C!important',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: `"Copyright Klim Type Foundry", sans-serif !important`,
          fontSize: '16px !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: `"Copyright Klim Type Foundry", sans-serif !important`,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          // Commented style for MuiStepConnector-root
          ' -webkit-flex': 'none',
          '-ms-flex': '1 1 auto',
          flex: 'none',
          width: '151px',
          height: '4px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontWeight: 'bold', // Set the desired font weight
        },
      },
    },
  },
});

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dispatch } = useContext(store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const userData = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const userDataSession = sessionStorage.getItem('user');
    const tokenSession = sessionStorage.getItem('token');

    if (store) {
      if (userData && token) {
        const parsedUserData = JSON.parse(userData);
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: parsedUserData,
            accessToken: token,
          },
        });
      } else if (userDataSession && tokenSession) {
        const parsedUserData = JSON.parse(userDataSession);
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: parsedUserData,
            accessToken: tokenSession,
          },
        });
      } else {
        localStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        localStorage.removeItem('user');
        if (pathname.startsWith('/create-password') || pathname.startsWith('/reset-password')) {
          // Allow the user to see the page with a specific pattern (e.g., plan/123214234);
          return;
        } else {
          navigate('/login');
        }
      }
    }
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div>
          <div className="resize-app">
            <img src={uppershape} alt="Uppershape" className="resize-image" />
            <img src={lowershape} alt="Lowershape" className="resize-image" />
            <div className="resize-window">
              <div className="resize-window-header">
                <h3>Change the size of your screen!</h3>
              </div>
              <div className="resize-window-description">
                <p>To use our application please resize your screen!</p>
              </div>
            </div>
          </div>
          <div className="App">
            <Routes>
              <Route path="/dashboard" element={<AdminPanelSidebar />} />
              <Route path="/profile" element={<ProfileSettings />} />
              <Route path="/create-password" element={<CreatePassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/login" element={<Login />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate replace to="/not-found" />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;

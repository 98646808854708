import { Avatar, Box, Button, Dialog, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  alabaster,
  black,
  gallery,
  mercury,
  primary,
  shark,
  warning,
  white,
  wildSand,
} from '../constants/colors';
import InputWithLabel from '../components/common/InputWithLabel';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { ReactComponent as AddPhoto } from '../assets/imgs/add_photo_alternate.svg';
import httpClient from '../config/http.service';
import { ResizeImageSettings } from './ResizeImageSettings';
import { showNotification } from '../components/common/Toast';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from './canvasUtils';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const ManageCompanyModal = ({
  open,
  handleClose,
  handleDelete,
  handleUpdate,
  company,
  companyPhoto,
  setCompanyPhoto,
  setCompanyPhotoDetails,
  handleCompanyDetails,
  handleAdd,
  type = 'manage',
  removeImage,
}) => {
  const [user, setUser] = useState();
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [imageSrcCompany, setImageSrcCompany] = useState(null);

  useEffect(() => {
    if (company?.hasAvatar && company?._id) getCompanyLogo(company._id);
    else {
      setImageSrcCompany(null);
      setCompanyPhoto(null);
    }
  }, [company?._id]);

  const getCompanyLogo = async () => {
    httpClient
      .get(`api/file/get/${company._id}?width=80&height=80`)
      .then((res) => {
        const imageData = new Uint8Array(res.data);
        const blob = new Blob([imageData], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setImageSrcCompany(imageUrl);
        setCompanyPhoto(imageUrl);
      })
      .catch((err) => {
        // if (err?.response?.data?.code !== 401)
        //   showNotification('error', err?.response?.data?.message);
      });
  };

  const onFileChange = async (fileForResize, type) => {
    if (fileForResize) {
      const file = fileForResize;
      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn('failed to detect the orientation');
      }
      setImageSrcCompany(imageDataUrl);
      setCompanyPhotoDetails(fileForResize);
    }
  };

  const cancelResizing = (type) => {
    if (company?.hasAvatar && company?._id) getCompanyLogo(company._id);
    else {
      setImageSrcCompany(null);
      setCompanyPhoto(null);
    }
  };

  const removePhoto = (type) => {
    if (type === 'company' && company) {
      httpClient
        .patch('api/company/remove-avatar', {
          _id: company._id,
        })
        .then(() => {
          setCompanyPhotoDetails(null);
          setCompanyPhoto(null);
          setImageSrcCompany(null);
        })
        .catch((err) =>
          showNotification('error', err?.response?.data?.message)
        );
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e, type) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChange(e.dataTransfer.files[0], type);
    }
  };

  const handleChangeUpload = function (e, type) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onFileChange(e.target.files[0], type);
    }
  };
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleUpdateNew = () => {
    handleUpdate()
    setImageSrcCompany(null)
  }

  const handleAddNew = () => {
    handleAdd()
    setImageSrcCompany(null)
  }

  const handleCloseNew = () => {
    handleClose()
    setImageSrcCompany(null)
  }

  return (
    <Dialog open={open} onClose={handleCloseNew} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" textAlign={'left'} sx={styles.heading}>
        Manage Company
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleCloseNew} />
      </Typography>

      <Box sx={styles.avatarBox}>
        {imageSrcCompany ? (
          companyPhoto ? (
            <Box sx={styles.hasImage}>
              <Avatar
                sx={{ ...styles.avatar, borderRadius: '6px' }}
                src={companyPhoto}
              />
              <Box sx={styles.rightSide}>
                <Box sx={styles.btnsWrapper}>
                  <Button
                    type="outlined"
                    sx={styles.update}
                    onClick={() => {
                      setCompanyPhotoDetails(null);
                      setCompanyPhoto(null);
                    }}
                  >
                    Change
                  </Button>
                  <Button
                    type="outlined"
                    sx={styles.cancel}
                    onClick={() => removePhoto('company')}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <div style={{ height: '550px' }}>
              <ResizeImageSettings
                imageSrcCompany={imageSrcCompany}
                cancelResizing={cancelResizing}
                setCompanyPhoto={setCompanyPhoto}
                setCompanyPhotoDetails={setCompanyPhotoDetails}
                data={user}
                typeOfImage={'company'}
                company={company}
                setData={setUser}
              />
            </div>
          )
        ) : (
          <Box sx={styles.dragNdrop}>
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                ref={inputRef}
                type="file"
                hidden
                id="input-file-upload"
                multiple={false}
                onChange={(e) => handleChangeUpload(e, 'user')}
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? 'drag-active' : ''}
              >
                <div style={styles.div}>
                  <AddPhoto />
                  <Typography variant="h5" sx={styles.drag}>
                    <span style={{ opacity: '0.6' }}>
                      Drag and drop an image, or{' '}
                    </span>
                    <span style={{ color: primary, opacity: '1' }}>Browse</span>
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={styles.minimum}
                    onClick={onButtonClick}
                  >
                    Minimum width 128px. Max 2MB.
                  </Typography>
                </div>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={(e) => handleDrop(e, 'user')}
                ></div>
              )}
            </form>
          </Box>
        )}
      </Box>
      <Box sx={styles.name}>
        <InputWithLabel
          label="Company Name"
          name="title"
          value={company?.title || ''}
          placeholder="Enter company name"
          styles={{ marginRight: '24px' }}
          handleChange={handleCompanyDetails}
        />

        <InputWithLabel
          label="Company Webiste (optional)"
          name="website"
          value={company?.website || ''}
          placeholder="Enter company website"
          handleChange={handleCompanyDetails}
        />
      </Box>
      {/* <Box sx={styles.name}>
        <InputWithLabel
          label="Main Contact Name"
          name="mainContactName"
          value={company?.mainContactName || ''}
          placeholder="Enter contact name"
          styles={{ marginRight: '24px' }}
          handleChange={handleCompanyDetails}
        />

        <InputWithLabel
          label="Main Contact Email Address"
          name="mainContactEmail"
          value={company?.mainContactEmail || ''}
          placeholder="Enter contact email address"
          handleChange={handleCompanyDetails}
        />
      </Box> */}
      <Box
        style={{
          ...styles.modalBtn,
          justifyContent: type === 'manage' ? 'space-between' : 'flex-end',
        }}
      >
        {type === 'manage' && (
          <Typography
            variant="contained"
            style={styles.delete}
            onClick={handleDelete}
          >
            Delete company
          </Typography>
        )}
        <Box sx={styles.btns}>
          <Button
            variant="contained"
            style={styles.cancel}
            onClick={handleCloseNew}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={styles.update}
            onClick={type === 'manage' ? handleUpdateNew : handleAddNew}
          >
            {type === 'manage' ? 'Update Changes' : 'Add Company'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '582px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '0',
    width: '100%',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '40px',
    maxWidth: '315px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0px',
    width: '100%',
    marginTop: '24px',
  },
  cancel: {
    color: black,
    padding: '8px 16px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
    textTransform: 'unset',
  },
  update: {
    color: white,
    padding: '8px 16px',
    // background: 'rgba(2, 17, 17, 0.25)',
    background: primary,
    borderRadius: '100px',
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: 'SoehneBold',
    '&:hover': { background: primary },
    textTransform: 'unset',
  },
  delete: {
    color: warning,
    padding: '8px 16px',
    borderRadius: '100px',
    fontFamily: 'SoehneBold',
    boxShadow: 'none',
    background: white,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    '&:hover': { background: 'none' },
    textTransform: 'unset',
  },
  avatarBox: {
    width: ' 100%',
    marginTop: '26px',
  },
  companyLogo: {
    height: '64px',
    width: '64px',
    border: `1px solid ${gallery}`,
    backgroundColor: alabaster,
    color: black,
    fontFamily: 'InterBold',
    fontSize: '12px',
    textAlign: 'center',
  },
  hasImage: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '15px',
  },
  btnsWrapper: {
    display: 'flex',
    gap: '10px',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    padding: '24px',
  },
  drag: {
    fontFamily: 'InterMedium',
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
  },
  minimum: {
    fontFamily: 'InterRegular',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  avatar: {
    height: '80px',
    width: '80px',
    border: 'none',
    objectFit: 'contain',
  },
  btnInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  uploadBtn: {
    padding: '8px 16px',
    border: ` 1px solid rgba(0, 0, 0, 0.6)`,
    height: '40px',
    fontFamily: 'InterRegular',
    color: 'rgba(0, 0, 0, 0.7)',
    '.MuiButton-outlined:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
      color: 'rgba(0, 0, 0, 0.88) !important',
    },
  },
  imgInfo: {
    fontFamily: '"InterRegular',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: '5px',
  },
  removeBtn: {
    padding: '8px 16px',
    borderRadius: '59px',
    height: '40px',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    backgroundColor: warning,
    color: white,
    border: 'none',
    '&:hover': {
      color: warning,
      borderColor: warning,
      backgroundColor: white,
    },
  },
  checkboxes: { width: '100%' },
  modalBtn: {
    display: 'flex',
    width: '100%',
    marginTop: '40px',
  },
  renewalDate: {
    fontSize: '16px',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    width: '100%',
    color: black,
    fontWeight: 500,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    marginBottom: '5px',
    transform: 'translate(0, -1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  imgInfo: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: black,
    marginTop: '5px',
  },
};

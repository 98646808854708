import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import { black, mercury, warning } from '../constants/colors';

export const DeleteCompanyModal = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Delete Company
      </Typography>
      <Typography variant="h5" sx={styles.body}>
        Are you sure you want to delete this company?
      </Typography>
      <Box sx={styles.btns}>
        <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={styles.delete} onClick={handleDelete}>
          Delete Company
        </Button>
      </Box>{' '}
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '410px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '12px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '40px',
    maxWidth: '315px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    padding: '8px 16px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  delete: {
    color: 'white',
    padding: '8px 16px',
    borderRadius: '100px',
    fontFamily: 'SoehneBold',
    boxShadow: 'none',
    background: warning,
    '&:hover': { background: warning },
  },
};

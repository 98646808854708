import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InputWithLabel from '../common/InputWithLabel';
import { black, mercury, shark, wildSand } from '../../constants/colors';

export const UpdateNameModal = ({
  open,
  handleClose,
  updateName,
  user,
  handleChange,
  handleEnter,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        Update Profile Details
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <Box sx={styles.name}>
        <InputWithLabel
          label="First name"
          name="firstName"
          value={user?.firstName}
          onKeyDown={handleEnter}
          placeholder="John"
          styles={{ marginRight: '24px' }}
          handleChange={(e) => handleChange(e, 'firstName')}
        />

        <InputWithLabel
          label="Last name"
          name="lastName"
          value={user?.lastName}
          onKeyDown={handleEnter}
          placeholder="Doe"
          handleChange={(e) => handleChange(e, 'lastName')}
        />
      </Box>
      <Box sx={styles.name}>
        <InputWithLabel
          label="Job title"
          name="jobTitle"
          value={user?.jobTitle}
          onKeyDown={handleEnter}
          placeholder="CEO"
          handleChange={(e) => handleChange(e, 'jobTitle')}
        />
      </Box>
      <Box sx={styles.name}>
        <InputWithLabel
          label="Linkedin profile"
          name="linkedin"
          value={user?.linkedin}
          onKeyDown={handleEnter}
          placeholder="https://linkedin.com"
          handleChange={(e) => handleChange(e, 'linkedin')}
        />
      </Box>

      <Box sx={styles.btns}>
        <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={styles.template} onClick={updateName}>
          Update
        </Button>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    fontSize: '16px',
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    fontSize: '16px',
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0px',
  },
};

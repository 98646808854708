import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import {
  black,
  porcelain,
  primary,
  warning,
  white,
} from '../../../constants/colors';
import {
  CompaniesActiveIcon,
  CompaniesIcon,
  UsersActiveIcon,
} from '../../../assets';
import { UsersIcon } from '../../../assets';
import { SETTINGS_TAB } from '../../../utils/enums';
import PaidIcon from '@mui/icons-material/Paid';
import { Dashboard } from '../../dashboard';
import { FreeUsers } from '../../freeUsers';
import AdminPanelHeader from '../Headers/headers';
import { PaidUsers } from '../../paidUsers';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AdminUsers } from '../../adminUsers';
import { store } from '../../../Store';
import { InvitedUsers } from '../../inivitedUsers';

export const AdminPanelSidebar = () => {
  const { state } = useContext(store);
  const [active, setActive] = useState('companies');

  return (
    <Container maxWidth="xxl" sx={styles.container}>
      <AdminPanelHeader />
      <Grid container spacing={2} style={styles.grid}>
        <Grid item xs={2} style={{ paddingTop: '0' }}>
          <Box style={styles.sidebar}>
            <Box
              style={
                active === SETTINGS_TAB.companies
                  ? styles.activeTab
                  : styles.tab
              }
              onClick={() => {
                setActive('companies');
                // navigate('/dashboard');
              }}
            >
              <Box sx={styles.iconAndName}>
                <img
                  src={
                    active === SETTINGS_TAB.companies
                      ? CompaniesActiveIcon
                      : CompaniesIcon
                  }
                  alt="Companies"
                  style={styles.image}
                />
                <Typography
                  color={active === SETTINGS_TAB.companies ? white : black}
                  fontWeight={active === SETTINGS_TAB.companies ? 700 : 400}
                  sx={styles.tabName}
                >
                  Companies
                </Typography>
              </Box>
            </Box>
            <Box
              style={
                active === SETTINGS_TAB.paidUsers
                  ? styles.activeTab
                  : styles.tab
              }
              onClick={() => {
                setActive('paidUsers');
                // navigate('/paidUsers');
              }}
            >
              <Box sx={styles.iconAndName}>
                <PaidIcon
                  style={{
                    ...styles.image,
                    color:
                      active === SETTINGS_TAB.paidUsers ? 'white' : '#686D75',
                  }}
                />
                <Typography
                  color={active === SETTINGS_TAB.paidUsers ? white : black}
                  fontWeight={active === SETTINGS_TAB.paidUsers ? 700 : 400}
                  sx={styles.tabName}
                >
                  Paid Users
                </Typography>
              </Box>
            </Box>
            <Box
              style={
                active === SETTINGS_TAB.freeUsers
                  ? styles.activeTab
                  : styles.tab
              }
              onClick={() => {
                setActive('freeUsers');
                // navigate('/freeUsers');
              }}
            >
              <Box sx={styles.iconAndName}>
                <img
                  src={
                    active === SETTINGS_TAB.freeUsers
                      ? UsersActiveIcon
                      : UsersIcon
                  }
                  alt="Users"
                  style={styles.image}
                />
                <Typography
                  color={active === SETTINGS_TAB.freeUsers ? white : black}
                  fontWeight={active === SETTINGS_TAB.freeUsers ? 700 : 400}
                  sx={styles.tabName}
                >
                  Free Users
                </Typography>
              </Box>
            </Box>
            <Box
              style={
                active === SETTINGS_TAB.inivitedUsers
                  ? styles.activeTab
                  : styles.tab
              }
              onClick={() => {
                setActive('inivitedUsers');
              }}
            >
              <Box sx={styles.iconAndName}>
                <img
                  src={
                    active === SETTINGS_TAB.inivitedUsers
                      ? UsersActiveIcon
                      : UsersIcon
                  }
                  alt="Companies"
                  style={styles.image}
                />
                <Typography
                  color={active === SETTINGS_TAB.inivitedUsers ? white : black}
                  fontWeight={active === SETTINGS_TAB.inivitedUsers ? 700 : 400}
                  sx={styles.tabName}
                >
                  Invited Users
                </Typography>
              </Box>
            </Box>
            {state?.data?.user?.roles?.includes('super_admin') && (
              <Box
                style={
                  active === SETTINGS_TAB.adminUsers
                    ? styles.activeTab
                    : styles.tab
                }
                onClick={() => {
                  setActive('adminUsers');
                  // navigate('/adminUsers');
                }}
              >
                <Box sx={styles.iconAndName}>
                  <AdminPanelSettingsIcon
                    style={{
                      ...styles.image,
                      color:
                        active === SETTINGS_TAB.adminUsers
                          ? 'white'
                          : '#686D75',
                    }}
                  />
                  <Typography
                    color={active === SETTINGS_TAB.adminUsers ? white : black}
                    fontWeight={active === SETTINGS_TAB.adminUsers ? 700 : 400}
                    sx={styles.tabName}
                  >
                    Admin Users
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={10} style={{ padding: '0' }}>
          <Box style={styles.settings}>
            {active === SETTINGS_TAB.companies ? <Dashboard /> : null}
            {active === SETTINGS_TAB.freeUsers ? <FreeUsers /> : null}
            {active === SETTINGS_TAB.paidUsers ? <PaidUsers /> : null}
            {active === SETTINGS_TAB.inivitedUsers ? <InvitedUsers /> : null}
            {active === SETTINGS_TAB.adminUsers &&
            state?.data?.user?.roles.includes('super_admin') ? (
              <AdminUsers />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = {
  container: {
    backgroundColor: porcelain,
    paddingBottom: '49px',
    height: '100vh',
    padding: '0  !important',
  },
  sidebar: {
    backgroundColor: white,
    padding: '32px 16px',
    marginTop: '0',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  settings: {
    backgroundColor: white,
    borderRadius: '16px',
    minHeight: '100px',
  },
  tab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: white,
    marginBottom: '24px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  activeTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: primary,
    marginBottom: '24px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  iconAndName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media screen and (min-width: 1052px)': {
      flexDirection: 'row',
    },
  },
  image: {
    width: '24px',
  },
  tabName: {
    display: 'none',
    marginLeft: '0',
    '@media screen and (min-width: 1052px)': {
      display: 'block',
      marginLeft: '15px',
    },
  },
  grid: {
    padding: 0,
    marginTop: 0,
  },
  delete: {
    color: warning,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  tooltip: {
    color: black,
    width: '20px',
    height: '20px',
    padding: 0,
    marginLeft: '5px',
  },
};

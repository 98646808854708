// Color system
export const white = '#FFFFFF';
export const black = '#1F1F1F';
export const primary = '#00818C';
export const text = '#4C4C4C';
export const lightGray = '#7A8081';
export const red = '#9D1600';
export const warning = '#FD7964';
export const paleSky = '#667085';
export const scorpion = '#5E5E5E';
export const silver = '#C7C7C7';
export const porcelain = '#F6F9F9';
export const mirage = '#1A2635';
export const lochinvar = '#268183';
export const shark = '#292D32';
export const alabaster = '#FAFAFA';
export const mercury = '#E8E8E8';
export const wildSand = '#F5F5F5';
export const foam = '#ECFEF7';
export const funGreen = '#035D3A';
export const chardon = '#FFF2F0';
export const swamp = '#002129';
export const gallery = '#EEEEEE';
export const bronze = '#604804';
export const earlyDawn = '##FFF9E9';

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { PlanListing } from "../components/Plans/PlanListing";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/imgs/search-normal.svg";
import { black, porcelain, white } from "../constants/colors";
import { sortPlans } from "../utils/utils";
import { toast } from "react-toastify";
import { PLAN_TAB } from "../utils/enums";
import httpClient from "../config/http.service";
import { store } from "../Store";
import useDebounce from "../utils/hooks/useDebounce";
import { NewAdminModal } from "../components/Settings/NewAdminModal";
import { pageSize } from "../utils/constants";

export const AdminUsers = () => {
  const { state } = useContext(store);
  const getActiveState = useLocation();
  const [active] = useState(getActiveState?.state?.active ?? "maps");
  const [users, setUsers] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstTimeUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [update, setUpdate] = useState(false);
  const [selectedSortDirection, setSelectedSortDirection] = useState("asc");
  const [selectedSortOption, setSelectedSortOption] = useState("firstName");
  const [showNewAdminModal, setShowNewAdminModal] = useState(false);
  const [type, setType] = useState("manage");
  const [newAdmin, setNewAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderAfterPageChange, setRenderAfterPageChange] = useState(true);

  useEffect(() => {
    if (searchTerm !== "") {
      getUsers(searchTerm);
      setRenderAfterPageChange(false);
      setPageNumber(1);
    } else {
      getUsers();
      setRenderAfterPageChange(true);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (state?.data?.user && renderAfterPageChange) {
      getUsers();
    }
  }, [state?.data, update, pageNumber]);

  const getUsers = async (name = "") => {
    try {
      const locPageNumber = !name ? pageNumber : 1;
      const res = await httpClient.get(`api/user/get-admins`, {
        params: { pageNumber: locPageNumber, pageSize, name },
      });
      const valueToString = res.data.admins;
      setTotalPages(res.data.totalPages ?? 0);
      let newPlans = [];

      for (let el of valueToString) {
        let obj = {};
        obj._id = el._id + "";
        obj.firstName = el.firstName;
        obj.lastName = el.lastName;
        obj.email = el.email;

        newPlans.push(obj);
      }

      setUsers(newPlans);
    } catch (err) {
      toast.info(err?.response?.data?.message);
    }
  };

  const handleSearch = (value) => setSearchTerm(value);

  const onClickRow = (obj, index) => {
    setSelectedRow(obj);
  };

  const handleSortChange = (item) => {
    if (active === PLAN_TAB.maps) {
      setSelectedSortOption(item);
      setSelectedSortDirection(
        selectedSortDirection === "asc" ? "desc" : "asc"
      );
      const sortedArray = sortPlans(
        item,
        users,
        selectedSortDirection === "asc" ? "desc" : "asc"
      );
      setUsers(sortedArray);
    }
  };

  const inviteAdmin = () => {
    httpClient
      .post("api/user/invite-admin", {
        firstName: newAdmin.firstName,
        lastName: newAdmin.lastName,
        email: newAdmin.email,
      })
      .then(() => {
        toast.success(
          `Successfully invited ${newAdmin.firstName} ${newAdmin.lastName} to the admin panel!`
        );
        setShowNewAdminModal(false);
        setNewAdmin({
          firstName: "",
          lastName: "",
          email: "",
        });
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const handleInviteChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  const updateUser = () => {
    httpClient
      .patch("api/user/admin-update-user", {
        _id: newAdmin._id,
        firstName: newAdmin.firstName,
        lastName: newAdmin.lastName,
        email: newAdmin.email,
      })
      .then(() => {
        toast.success(
          `Successfully uodated ${newAdmin.firstName} ${newAdmin.lastName}!`
        );
        setShowNewAdminModal(false);
        setNewAdmin({
          firstName: "",
          lastName: "",
          email: "",
        });
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  const handleDelete = () => {
    httpClient
      .delete("api/user/delete-admin", {
        data: {
          _id: newAdmin._id,
        },
      })
      .then(() => {
        toast.success(
          `Successfully deleted ${newAdmin.firstName} ${newAdmin.lastName} from the admin panel!`
        );
        setShowNewAdminModal(false);
        setNewAdmin({
          firstName: "",
          lastName: "",
          email: "",
        });
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
  };

  return (
    <Box sx={styles.main}>
      {!firstTimeUser ? (
        <Box sx={{ padding: "23px 16px" }}>
          <Container maxWidth="xxl">
            <Grid container sx={styles.tabs}>
              <Grid item sx={styles.tabsItem}>
                <Typography sx={styles.title}>Admin Users</Typography>
              </Grid>
              <Grid item sx={styles.header_btns}>
                <Input
                  id="search"
                  placeholder="Search by User name"
                  sx={styles.search_input}
                  onChange={(event) => handleSearch(event.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ marginLeft: "17.5px" }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  sx={styles.add_btn}
                  onClick={() => {
                    setNewAdmin({
                      firstName: "",
                      lastName: "",
                      email: "",
                    });
                    setType("add");
                    setShowNewAdminModal(true);
                  }}
                >
                  Add Admin
                  <AddCircleOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          </Container>
          <PlanListing
            type="adminUsers"
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onClickRow={onClickRow}
            active={active}
            plans={users}
            setPlans={setUsers}
            update={update}
            setUpdate={setUpdate}
            handleSortChange={handleSortChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
            handleManage={(e, single) => {
              setNewAdmin(single);
              setType("manage");
              setShowNewAdminModal(true);
            }}
            page={pageNumber}
            setPage={setPageNumber}
            totalPages={totalPages}
          />
          <NewAdminModal
            open={showNewAdminModal}
            handleClose={() => setShowNewAdminModal(false)}
            inviteAdmin={inviteAdmin}
            handleInviteChange={handleInviteChange}
            updateUser={updateUser}
            handleDelete={handleDelete}
            newAdmin={newAdmin}
            type={type}
          />
        </Box>
      ) : (
        <Box>Test</Box>
      )}
    </Box>
  );
};

const styles = {
  main: {
    backgroundColor: `${porcelain} !important`,
    minHeight: "100vh !important",
  },
  header: {
    display: "flex !important",
    justifyContent: "space-between !important",
    flexWrap: "wrap !important",
    alignItems: "center",
    marginBottom: "25px",
  },
  search_input: {
    backgroundColor: white,
    width: "250px",
    height: "40px",
    border: "1px solid #F4F4F4",
    borderRadius: "32px",
    fontSize: "14px",
    marginRight: "12px",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingVertical: "20px",
    marginBottom: "20px",
    padding: "9px",
    "@media screen and (min-width: 500px)": {
      justifyContent: "space-between",
    },
  },
  tabsItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    "@media screen and (min-width: 500px)": {
      flexDirection: "row",
    },
    "@media screen and (min-width: 1150px)": {
      marginBottom: "0px",
    },
  },
  title: {
    color: black,
    fontSize: "18px",
    fontFamily: "SoehneBold",
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0",
    background: white,
    borderRadius: "8px",
    padding: "10px 14px",
    gap: "8px",
    "@media screen and (min-width: 500px)": {
      marginLeft: "20px",
    },
  },
  header_btns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginLeft: "20px",
    "@media screen and (min-width: 500px)": {
      marginLeft: "0",
    },
  },
  menu: {
    borderRadius: 8,
  },
  add_btn: {
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "24px",
    height: "40px",
    padding: "10px 20px",
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "transparent",
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    fontFamily: "SoehneBold",
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  black,
  mercury,
  primary,
  shark,
  warning,
  white,
  wildSand,
} from '../constants/colors';
import InputWithLabel from '../components/common/InputWithLabel';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { ReactComponent as ArrowDownIcon } from '../assets/imgs/arrow-down.svg';
import { Hint } from 'react-autocomplete-hint';
import { AppDatePicker } from '../components/common/AppDatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { CreateNewTemplate } from './CreateNewTemplate';

export const ManageUserModal = ({
  open,
  handleClose,
  openDeleteUserModal,
  openDisableModal,
  handleUpdate,
  user,
  handleUserDetails,
  companiesForSelect,
  currenciesForSelect,
  companies,
  language,
  hosted,
  handleAdd,
  type = 'manage',
  handleCheckbox,
  handleTeamname,
  templates = [],
  updateTemplates,
  setUpdateTemplates,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showCreateNewTemplate, setShowCreateNewTemplate] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (user?.companyId) {
      const findCompany = companies.find((el) => el._id === user.companyId);
      let newArray = [];
      if (findCompany?.teams && findCompany?.teams.length > 0) {
        findCompany.teams.map((el) => {
          if (el) newArray.push(el);
        });
      }

      setTeams(newArray);
      // if (findCompany.teams[0] !== null) setTeams(findCompany.teams);
    }
  }, [user?.companyId]);

  const redirectToWebApp = (name) => {
    const url = `${process.env.REACT_APP_WEB}view-template/${user?.templateId}`;
    if (name === 'view') {
      window.open(`${url}`, '_blank');
    } else {
      window.open(`${url}/edit`, '_blank');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" textAlign={'left'} sx={styles.heading}>
        {type === 'manage' ? 'Manage User' : 'Add User'}
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>
      <Box sx={styles.name}>
        <InputWithLabel
          label="First Name"
          name="firstName"
          value={user?.firstName || ''}
          placeholder="Enter first name"
          styles={{ marginRight: '24px', marginTop: '5px' }}
          handleChange={handleUserDetails}
          modalType={type}
        />
        <InputWithLabel
          label="Last Name"
          name="lastName"
          value={user?.lastName || ''}
          styles={{ marginTop: '5px' }}
          placeholder="Enter user last name"
          handleChange={handleUserDetails}
          modalType={type}
        />
      </Box>
      <Box sx={styles.name}>
        <InputWithLabel
          label="User Email Address"
          name="email"
          value={user?.email || ''}
          placeholder="Enter user email"
          styles={{ marginRight: '24px', marginTop: '5px' }}
          handleChange={handleUserDetails}
          modalType={type}
        />
        <InputWithLabel
          label="Job Title"
          name="jobTitle"
          value={user?.jobTitle || ''}
          styles={{ marginTop: '5px' }}
          placeholder="Enter user job title"
          handleChange={handleUserDetails}
          modalType={type}
        />
      </Box>
      <Box sx={styles.name}>
        <FormControl style={{ width: '291px', height: '103px' }}>
          <Typography sx={styles.select}>Language</Typography>
          <Select
            value={user?.language || ''}
            name="language"
            label="Language"
            placeholder="Choose language"
            onChange={handleUserDetails}
            IconComponent={ArrowDownIcon}
            style={{
              backgroundColor: 'rgb(250, 250, 250)',
              height: '49px',
              marginRight: '24px',
            }}
          >
            {language?.map((lang) => (
              <MenuItem key={lang.value} value={lang.value}>
                {lang?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: '291px', height: '103px' }}>
          <Typography sx={styles.select}>Hosted</Typography>
          <Select
            value={user?.hosted || ''}
            name="hosted"
            label="Hosted"
            placeholder="Choose hosted"
            onChange={handleUserDetails}
            IconComponent={ArrowDownIcon}
            style={{ backgroundColor: 'rgb(250, 250, 250)', height: '49px' }}
          >
            {hosted?.map((host) => (
              <MenuItem key={host.value} value={host.value}>
                {host.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.name}>
        <FormControl style={{ width: '100%', height: '103px' }}>
          <Typography sx={styles.select}>User Currency</Typography>
          <Select
            value={user?.defaultCurrency || ''}
            name="defaultCurrency"
            label="Choose currency"
            placeholder="Choose currency"
            onChange={handleUserDetails}
            IconComponent={ArrowDownIcon}
            style={{
              backgroundColor: 'rgb(250, 250, 250)',
              height: '49px',
            }}
          >
            {currenciesForSelect?.map((currency) => (
              <MenuItem key={currency.symbol} value={currency.symbol}>
                {currency.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.name}>
        <FormControl style={{ width: '291px', height: '103px' }}>
          <Typography sx={styles.select}>Company Name</Typography>
          <Select
            value={user?.companyId || ''}
            name="companyId"
            label="Choose company"
            placeholder="Choose company"
            onChange={handleUserDetails}
            IconComponent={ArrowDownIcon}
            style={{
              backgroundColor: 'rgb(250, 250, 250)',
              height: '49px',
              marginRight: '24px',
            }}
          >
            {companiesForSelect?.map((company) => (
              <MenuItem key={company.value} value={company.value}>
                {company.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: '291px', height: '103px' }}>
          <Typography sx={styles.select}>Select Template</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Select
              value={user?.templateId || ''}
              name="template"
              label="Template"
              placeholder="Choose template"
              onChange={handleUserDetails}
              IconComponent={ArrowDownIcon}
              style={{
                backgroundColor: 'rgb(250, 250, 250)',
                height: '49px',
                width: user?.paid ? '100%' : '103%',
              }}
            >
              {templates?.map((template) => {
                if (template.label === 'Create New Template') {
                  return (
                    <MenuItem
                      key={template.value}
                      value={template.value}
                      onClick={() => setShowCreateNewTemplate(true)}
                    >
                      {template.label}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={template.value} value={template.value}>
                      {template.label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {user?.templateId && user?.templateId !== 'addNew' && (
              <Tooltip
                arrow
                title="Preview template in the application."
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: white,
                      color: black,
                      width: '170px',
                      height: 'auto',
                      textAlign: 'center',
                      '& .MuiTooltip-arrow': {
                        color: white,
                      },
                    },
                  },
                }}
              >
                <VisibilityIcon
                  sx={{ marginLeft: '20px', cursor: 'pointer' }}
                  onClick={() => redirectToWebApp('view')}
                />
              </Tooltip>
            )}

            {user?.templateId && user?.templateId !== 'addNew' && (
              <Tooltip
                arrow
                title="Edit template in the application."
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: white,
                      color: black,
                      width: '170px',
                      height: 'auto',
                      textAlign: 'center',
                      '& .MuiTooltip-arrow': {
                        color: white,
                      },
                    },
                  },
                }}
              >
                <EditIcon
                  sx={{ marginLeft: '20px', cursor: 'pointer' }}
                  onClick={() => redirectToWebApp('edit')}
                />
              </Tooltip>
            )}
          </Box>
        </FormControl>
      </Box>
      {user?.paid && (
        <Box sx={styles.name}>
          <InputWithLabel
            label="Main Contact Name"
            name="mainContactName"
            value={user?.mainContactName || ''}
            placeholder="Enter contact name"
            styles={{ marginRight: '24px' }}
            handleChange={handleUserDetails}
          />

          <InputWithLabel
            label="Main Contact Email Address"
            name="mainContactEmail"
            value={user?.mainContactEmail || ''}
            placeholder="Enter contact email address"
            handleChange={handleUserDetails}
          />
        </Box>
      )}
      {user?.paid && (
        <Box sx={styles.name}>
          <Box>
            <Typography sx={styles.renewalDate}>Renewal Date</Typography>
            <AppDatePicker
              name="renewalDate"
              value={user?.renewalDate}
              inputStyles={styles.targetDateInput}
              slotPropsStyles={styles.dateSlotPropsStyles}
              handleChange={handleUserDetails}
            />
          </Box>
          <FormControl style={{ width: '291px', height: '85px' }}>
            <Typography sx={styles.select}>Team Name</Typography>
            <Hint
              style={{ width: '100%' }}
              options={teams?.length > 0 ? teams?.map((option) => option) : []}
              allowEnterFill
              allowTabFill
            >
              <input
                style={styles.inputWithLabelCustom}
                name="teamname"
                value={user?.teamname || ''}
                placeholder="Enter team name"
                onChange={(e) => {
                  const enteredValue = e.target.value;
                  const suggestion = teams?.find(
                    (suggestion) =>
                      suggestion.toLocaleLowerCase() ===
                      enteredValue.toLocaleLowerCase()
                  );
                  handleTeamname(e, suggestion ?? '');
                }}
              />
            </Hint>
          </FormControl>
        </Box>
      )}
      <FormGroup sx={styles.checkboxes}>
        <FormControlLabel
          control={
            <Checkbox
              checked={user?.paid || false}
              onChange={(e) => handleCheckbox(e, 'paid')}
              name="paid"
            />
          }
          label="Paid user"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user?.canUpdateCompany || false}
              onChange={(e) => handleCheckbox(e, 'canUpdateCompany')}
              name="canUpdateCompany"
            />
          }
          label="Can Update Company"
        />
      </FormGroup>
      <Box
        style={{
          ...styles.modalBtn,
          justifyContent: type === 'manage' ? 'space-between' : 'flex-end',
        }}
      >
        {type === 'manage' && (
          <Box style={{ position: 'relative' }}>
            <Typography
              variant="contained"
              sx={styles.delete}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              Manage user access
              <ArrowDownIcon style={{ marginLeft: '5px' }} />
            </Typography>
            {openDropdown && (
              <Box style={styles.dropdown}>
                <Typography
                  onClick={() => {
                    openDisableModal();
                    setOpenDropdown(false);
                  }}
                  style={styles.disable}
                >
                  {user?.disabled ? 'Enable User' : 'Disable User'}
                </Typography>
                <Typography
                  onClick={() => {
                    openDeleteUserModal();
                    setOpenDropdown(false);
                  }}
                  style={styles.deleteBtn}
                >
                  Delete User
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Box sx={styles.btns}>
          <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={styles.update}
            onClick={type === 'manage' ? handleUpdate : handleAdd}
          >
            {type === 'manage' ? 'Update Changes' : 'Add User'}
          </Button>
        </Box>
      </Box>
      <CreateNewTemplate
        open={showCreateNewTemplate}
        handleClose={() => setShowCreateNewTemplate(false)}
        setShowCreateNewTemplate={setShowCreateNewTemplate}
        updateTemplates={updateTemplates}
        setUpdateTemplates={setUpdateTemplates}
      />
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '620px',
    height: '90vh',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '0',
    width: '100%',
  },
  modalBtn: {
    display: 'flex',
    width: '100%',
    marginTop: '40px',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0px',
    width: '100%',
    marginTop: '24px',
    minHeight: '103px',
  },
  cancel: {
    color: black,
    fontSize: '16px',
    padding: '8px 16px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  update: {
    color: white,
    fontSize: '16px',
    padding: '8px 16px',
    background: primary,
    borderRadius: '100px',
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: 'SoehneBold',
    marginLeft: '24px',
    '&:hover': { background: primary },
  },
  delete: {
    color: primary,
    fontSize: '16px',
    padding: '8px 16px',
    borderRadius: '100px',
    fontFamily: 'SoehneBold',
    boxShadow: 'none',
    background: white,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': { background: 'none' },
  },
  dropdown: {
    position: 'absolute',
    width: '140px',
    height: '45px',
    left: '12px',
    top: '40px',
    padding: '12px',
    backgroundColor: white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
  },
  disable: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: black,
    marginBottom: '12px',
    cursor: 'pointer',
  },
  deleteBtn: {
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: warning,
    cursor: 'pointer',
  },
  select: {
    fontSize: '16px',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    width: '100%',
    color: black,
    fontWeight: 500,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    marginBottom: '5px',
    transform: 'none',
  },
  buttonsView: {
    fontSize: '12px',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    width: '100%',
    color: black,
    fontWeight: 500,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    marginBottom: '5px',
    transform: 'none',
  },
  buttonsEdit: {
    fontSize: '12px',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    width: '100%',
    color: black,
    fontWeight: 500,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    marginBottom: '5px',
    transform: 'none',
  },
  inputWithLabelCustom: {
    marginBottom: '0px',
    transform: 'none',
    backgroundColor: 'rgb(250, 250, 250)',
    height: '23px',
    borderRadius: '4px',
    position: 'relative',
    border: '1px solid #EEEEEE',
    fontSize: '16px',
    width: '89%',
    padding: '12px 16px',
    transition:
      'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  inputWithLabel: {
    marginBottom: '0px',
    transform: 'none',
    '& .MuiFormLabelRootMuiInputLabelRoot': {
      transform: 'none',
    },
  },
  checkboxes: {
    width: '100%',
    marginTop: '20px',
  },
  targetDateInput: {
    fontSize: '16px',
    height: '49px',
    width: '267px',
    backgroundColor: 'rgb(250, 250, 250)',
    marginRight: '24px',
  },
  dateSlotPropsStyles: {
    openPickerButton: { sx: { ml: '-8px' } },
  },
  renewalDate: {
    fontSize: '16px',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    width: '100%',
    color: black,
    fontWeight: 500,
    fontFamily: '"Copyright Klim Type Foundry", sans-serif',
    marginBottom: '5px',
    // transform: 'translate(0, -1.5px) scale(0.75)',
    // transformOrigin: 'top left',
  },
};

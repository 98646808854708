import { SORT_DIRECTION } from './enums';
import moment from 'moment';

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const getInitials = (firstName, lastName) => {
  const initials = firstName?.charAt(0) + lastName?.charAt(0);

  if (initials) return initials.toUpperCase();
  else return '';
};

export const validateHttpLink = (link) => {
  const httpLinkPattern = /^(http:\/\/|https:\/\/)/;

  return httpLinkPattern.test(link);
};

export const countUnreadObjects = (arr) => {
  const unreadObjects = arr.filter((obj) => obj.read === false);
  return unreadObjects.length;
};

export const getInitial = (string) => {
  const initials = string.charAt(0).toUpperCase();

  return initials;
};

export const getInitialsFromString = (string) => {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const randomColor = () => {
  const hue = '200';
  const saturation = Math.floor(Math.random() * 51) + 50;
  const lightness = Math.floor(Math.random() * 31) + 35;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const sortPlans = (sortOption, plans, sortDirection) => {
  if (plans?.length > 0) {
    const sortedPlans = [...plans];
    switch (sortOption) {
      case 'title':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.title.localeCompare(b.title));
        else sortedPlans.sort((a, b) => b.title.localeCompare(a.title));

        break;
      case 'teamname':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.teamname.localeCompare(b.teamname));
        else sortedPlans.sort((a, b) => b.teamname.localeCompare(a.teamname));

        break;
      case 'totalTeams':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.totalTeams - b.totalTeams);
        else sortedPlans.sort((a, b) => b.totalTeams - a.totalTeams);
        break;
      case 'totalUsers':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.totalUsers - b.totalUsers);
        else sortedPlans.sort((a, b) => b.totalUsers - a.totalUsers);
        break;
      case 'companyName':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          );
        else
          sortedPlans.sort((a, b) =>
            b.companyName.localeCompare(a.companyName)
          );

        break;
      case 'numberOfPlans':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) => a.numberOfPlans - b.numberOfPlans);
        else sortedPlans.sort((a, b) => b.numberOfPlans - a.numberOfPlans);
        break;
      case 'mainContactName':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) =>
            a.mainContactName.localeCompare(b.mainContactName)
          );
        else
          sortedPlans.sort((a, b) =>
            b.mainContactName.localeCompare(a.mainContactName)
          );
        break;
      case 'mainContactEmail':
        if (sortDirection === SORT_DIRECTION.asc)
          sortedPlans.sort((a, b) =>
            a.mainContactEmail.localeCompare(b.mainContactEmail)
          );
        else
          sortedPlans.sort((a, b) =>
            b.mainContactEmail.localeCompare(a.mainContactEmail)
          );
        break;
      case 'closeDate':
        if (sortDirection === SORT_DIRECTION.asc) {
          sortedPlans.sort(
            (a, b) => new Date(a.closeDate) - new Date(b.closeDate)
          );
        } else
          sortedPlans.sort(
            (a, b) => new Date(b.closeDate) - new Date(a.closeDate)
          );

        break;
      case 'lastLogin':
        if (sortDirection === SORT_DIRECTION.asc) {
          sortedPlans.sort(
            (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
          );
        } else
          sortedPlans.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

        break;
      default:
        break;
    }

    return sortedPlans;
  }
};

export const getDarkerColor = (color) => {
  // Remove the '#' character if present
  const hex = color.replace('#', '');

  // Convert the color to RGB
  const rgb = parseInt(hex, 16);

  // Calculate the darker color
  const darkerRgb = (rgb >> 1) & 0x7f7f7f;

  // Convert the darker color back to hexadecimal
  const darkerColor = '#' + darkerRgb.toString(16).padStart(6, '0');

  return darkerColor;
};

export const getDateRanges = (item) => {
  let targetDates = [];

  if (item.activities && item.activities.length > 0) {
    item.activities.forEach((activity) => {
      if (activity.targetDate) {
        targetDates.push(activity.targetDate);
      }

      if (activity.subActivities && activity.subActivities.length > 0) {
        activity.subActivities.forEach((subActivity) => {
          if (subActivity.targetDate) {
            targetDates.push(subActivity.targetDate);
          }
        });
      }
    });
  }

  if (targetDates.length > 0) {
    const sortedDates = targetDates.sort((a, b) => new Date(a) - new Date(b));
    const startDate = moment(sortedDates[0].$d).format('MMM DD');
    const endDate = moment(sortedDates[sortedDates.length - 1].$d).format(
      'MMM DD'
    );

    return {
      startDate: startDate,
      endDate: item.activities.length > 1 ? endDate : null,
    };
  }

  return null;
};

export const getNumberOfCompletedActivitiesAndSubactivities = (data) => {
  let completedCount = 0;

  if (data?.activities) {
    data.activities.map((sub) => {
      if (sub.status === 'completed') {
        completedCount++;
      }

      if (sub.subActivities) {
        sub.subActivities.map((subActivity) => {
          if (subActivity.status === 'completed') {
            completedCount++;
          }
        });
      }
    });
  }

  return completedCount;
};

export const formatToTableDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate;
};

export const formatToTableDateWithHours = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  return formattedDate;
};

export const formatCurrency = (obj) => {
  if (obj.currency === 'dollar') {
    return `$${obj.amount}`;
  } else if (obj.currency === 'euro') {
    return `€${obj.amount}`;
  } else if (obj.currency === 'euro') {
    return `£${obj.amount}`;
  }
};

export const countProgress = (data) => {
  if (data?.stages) {
    const counts = data.stages.map((stage) => {
      const completedActivities = stage.activities.filter(
        (activity) => activity.status === 'completed'
      );
      const completedSubActivities = stage.activities.flatMap((activity) =>
        activity.subActivities.filter(
          (subActivity) => subActivity.status === 'completed'
        )
      );

      return {
        stageName: stage.name,
        completed: completedActivities.length + completedSubActivities.length,
        incompleted:
          stage.activities.length -
          completedActivities.length +
          (stage.activities.flatMap((activity) => activity.subActivities)
            .length -
            completedSubActivities.length),
        sum:
          completedActivities.length +
          completedSubActivities.length +
          (stage.activities.length -
            completedActivities.length +
            (stage.activities.flatMap((activity) => activity.subActivities)
              .length -
              completedSubActivities.length)),
      };
    });

    let completed = 0;
    let allStatuses = 0;
    counts.map((stage) => {
      completed += stage.completed;
      allStatuses += stage.sum;
    });

    return (completed / allStatuses) * 100;
  }
};

export const getTotalCount = (data) => {
  let totalCount = 0;
  if (data?.activities) {
    data.activities.map((activity) => {
      totalCount++;

      if (activity.subActivities) {
        activity.subActivities?.map((subActivity) => {
          totalCount++;
        });
      }
    });
  }

  return totalCount;
};

export const planAccessColumns = [
  { id: 'name', label: 'Name', minWidth: 170, type: 'string' },
  {
    id: 'title',
    label: 'Job title',
    minWidth: 100,
    align: 'center',
    type: 'string',
  },
  {
    id: 'userType',
    label: 'User type',
    minWidth: 100,
    align: 'center',
    type: 'string',
  },
  {
    id: 'companyName',
    label: 'Company name',
    minWidth: 100,
    align: 'center',
    type: 'string',
  },
  {
    id: 'status',
    label: 'View status',
    minWidth: 70,
    align: 'center',
    type: 'boolean',
  },
  {
    id: 'count',
    label: 'View count',
    minWidth: 70,
    align: 'center',
    type: 'number',
  },
  {
    id: 'lastViewed',
    label: 'Last viewed',
    minWidth: 80,
    align: 'center',
    format: (value) => value.toFixed(2),
    type: 'date',
  },
];

export const planAccessRows = [
  {
    name: 'Manuel Snr',
    email: 'manuel@manuelinc.com',
    title: 'Vendor Representative',
    userType: 'Vendor',
    companyName: 'Manuel Inc',
    status: true,
    count: 32,
    lastViewed: '23 Jan, 2022',
    time: '2:30pm',
    color: '#E7F6F9',
  },
  {
    name: 'James Banks',
    email: 'jamesbank@walmart.com',
    title: 'Marketing Coordinator',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 12,
    lastViewed: '20 Jan, 2022',
    time: '12:30pm',
    color: '#F9E7E7',
  },
  {
    name: 'Tanner Stafford',
    email: 'tanstaf@walmart.com',
    title: 'Sales Representative',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 23,
    lastViewed: '05 Jan, 2022',
    time: '7:30am',
    color: '#F3EBFF',
  },
  {
    name: 'Geoffrey Mott',
    title: 'Account Executive',
    userType: 'Customer',
    companyName: 'Walmart',
    status: true,
    count: 6,
    lastViewed: '18 Jan, 2022',
    time: '12:30pm',
    color: '#EDF9E7',
  },
  {
    name: 'Ronald Richman',
    title: 'Regional Sales Account Manager',
    userType: 'Customer',
    companyName: 'Walmart',
    status: false,
    count: 0,
    lastViewed: '',
    color: '#E7F8F9',
  },
  {
    name: 'Florencion Dorrance',
    title: 'Marketing Manager',
    userType: 'Customer',
    companyName: 'Walmart',
    status: false,
    count: 0,
    lastViewed: '',
    color: '#FFEBFF',
  },
  {
    name: 'Sam Smith',
    title: 'Sales Representative',
    userType: 'Partner',
    companyName: 'Innoson Motors',
    status: true,
    count: 23,
    lastViewed: '18 Jan, 2022',
    time: '12:30pm',
    color: '#FFFDEB',
  },
  {
    name: 'Samar Syed',
    title: 'Marketing Manager',
    userType: 'Others',
    companyName: 'Samar Enterprise',
    status: true,
    count: 23,
    lastViewed: '18 Jan, 2022',
    time: '2:30pm',
    color: '#FFF6EB',
  },
];

export const returnTemplateObj = (plan, template, createdTemplate) => {
  plan.stages?.map((stage) => {
    if (stage.activities?.length > 0) {
      stage.activities.map((activity) => {
        activity.customerTeam = [{ name: '', email: '', title: '' }];
        activity.vendorTeam = [{ name: '', email: '', title: '' }];
        activity.targetDate = '';
      });
    }
  });
  const newObj = {
    _id: createdTemplate._id,
    name: template.name,
    description: template.description,
    introduction: plan.introduction,
    customerCompany: '',
    customerChampion: '',
    vendorRepresentative: '',
    vendor: '',
    stages: plan.stages,
    closeDate: plan.closeDate,
    value: plan.value,
    stakeholders: plan.stakeholders,
    archived: plan.archived,
    hasAvatar: plan.hasAvatar,
    createdAt: new Date(),
    updatedAt: new Date(),
    userId: plan.userId,
    defaultTemplate: template.checked,
  };
  return newObj;
};

export const returnArrayOfUsers = (users) => {
  return users.map(({ firstName, lastName, email, jobTitle }) => ({
    user: `${firstName} ${lastName}`,
    email,
    jobTitle,
  }));
};

export const removeDuplicates = (arr) => {
  const uniqueIds = new Set();
  return arr.filter((obj) => {
    if (!uniqueIds.has(obj._id)) {
      uniqueIds.add(obj._id);
      return true;
    }
    return false;
  });
};

export const mockedPaidUsers = [
  {
    _id: '648ae344bb9aea3fb12df423',
    username: 'User 1',
    logo: '',
    companyName: 'Admin Panel',
    companyId: '64899a64319b8bc0c94aa44a',
    mainContactName: 'James Samson',
    mainContactEmail: 'james@samsom.com',
    tier: 'Teams',
    teamname: 'Admin panel team',
    lastLogin: 'Jun 15, 2023',
    disabled: false,
    email: 'admin@panel.com',
    deleted: false,
    firstName: 'New',
    lastName: 'User',
    jobTitle: 'CEO',
    hosted: 'US',
    language: 'EN',
    paid: true,
  },
  {
    _id: '64773a09ea77a0eebf6bd2e0',
    username: 'Lando Norris',
    logo: 'blob:http://localhost:3001/765b295e-6024-4fb5-a894-882c950f263d',
    companyName: 'HYPER',
    companyId: '6448f19b6fb4f040972d90b2',
    mainContactName: 'Vukasin Macura',
    mainContactEmail: 'macura3vukasin@gmail.com',
    tier: 'Teams',
    teamname: 'landov team',
    lastLogin: 'Jun 19, 2023',
    disabled: false,
    email: 'vukasin.macura@hyperether.com',
    deleted: false,
    firstName: 'Lando',
    lastName: 'Norris',
    jobTitle: 'Neki title',
    hosted: 'EU',
    language: 'EN',
    paid: true,
  },
];

export const mockedFreeUsers = [
  {
    _id: '64744eb058134b6d0faa4ba0',
    username: 'Vukasin Macura FREE',
    logo: 'blob:http://localhost:3001/11e133cd-decb-44ab-8cfc-19733676b991',
    companyName: 'Inquarter',
    companyId: '6448f754a94a652bad22805a',
    tier: 'Teams',
    teamname: 'Vulinjov team',
    numberOfPlans: 1,
    lastLogin: 'Jun 19, 2023',
    disabled: false,
    email: 'vule@hyperether.com',
    deleted: false,
    firstName: 'Vukasin',
    lastName: 'Macura',
    jobTitle: 'Admin role',
    hosted: 'EU',
    language: 'EN',
    paid: false,
  },
  {
    _id: '648c2e731200cef0bf0086a3',
    username: 'Max Verstappen FREE',
    logo: 'blob:http://localhost:3001/0479357b-135f-432e-83c8-bf96894dd9e6',
    companyName: 'HYPER',
    companyId: '6448f19b6fb4f040972d90b2',
    teamname: '',
    lastLogin: 'Not yet logged in',
    disabled: false,
    email: 'macura3vukasin+1@gmail.com',
    deleted: false,
    firstName: 'Max',
    lastName: 'Verstappen',
    jobTitle: 'Max Champion',
    hosted: 'US',
    language: 'EN',
    paid: false,
  },
];

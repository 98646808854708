import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NumericFormatCustom from './NumericFormatCustom';
import { alabaster, gallery, primary, swamp } from '../../constants/colors';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${gallery}`,
    fontSize: 16,
    width: '100%',
    padding: '12px 16px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: ['Inter', 'sans-serif'].join(','),
    '&:focus': { borderColor: swamp },
  },
}));

const InputWithLabel = ({
  type = 'text',
  field,
  name,
  value,
  min = 0,
  label,
  styles,
  placeholderStyles = {},
  rows = 4,
  helperText,
  placeholder = '',
  required = false,
  multiline = false,
  disabled = false,
  passwordResetLink,
  handleChange,
  onKeyDown,
  modalType,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        marginBottom: styles?.marginBottom
          ? styles.marginBottom
          : modalType === 'manage'
          ? '0'
          : '25px',
        position: 'relative',
        transform: 'none',
      }}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          sx={{
            '&.Mui-focused': { color: swamp },
            color: swamp,
            fontWeight: '500',
            width: '100%',
            transform: 'none',
            fontFamily: '"Copyright Klim Type Foundry", sans-serif',
          }}
        >
          {label}
        </InputLabel>
      )}
      <BootstrapInput
        onKeyDown={onKeyDown}
        type={showPassword ? 'text' : type}
        disabled={disabled}
        value={value}
        name={name}
        id={name}
        rows={rows}
        multiline={multiline}
        required={required}
        placeholder={placeholder}
        inputProps={{
          style: { backgroundColor: alabaster, ...styles },
          min,
        }}
        {...(field === 'number' && { inputComponent: NumericFormatCustom })}
        sx={{ '& ::placeholder': placeholderStyles }}
        onChange={handleChange}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {type === 'password' &&
        (showPassword ? (
          <VisibilityOffIcon
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={togglePasswordVisibility}
          />
        ) : (
          <VisibilityOutlinedIcon
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={togglePasswordVisibility}
          />
        ))}

      {passwordResetLink && (
        <Link
          to={'/reset-password'}
          style={{
            right: 0,
            position: 'absolute',
            fontSize: '12px',
            color: primary,
          }}
        >
          Reset Password
        </Link>
      )}
    </FormControl>
  );
};

export default InputWithLabel;

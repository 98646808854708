/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import InputWithLabel from '../components/common/InputWithLabel';
import styles from '../styles/login/login.module.css';
import { store } from '../Store';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import axios from 'axios';

export const Login = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(store);
  const [user, setUser] = useState({ email: '', password: '' });
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');

    if (storedUser && storedToken) {
      dispatch({
        type: 'SET_DATA',
        payload: {
          isAuthenticated: true,
          user: JSON.parse(storedUser),
          accessToken: storedToken,
          userImage: '',
        },
      });
      navigate('/dashboard');
    } else if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}api/user/admin-login`, {
        email: user.email,
        password: user.password,
      })
      .then((res) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            isAuthenticated: true,
            user: res.data?.user,
            accessToken: res.data?.token.accessToken,
            refreshToken: res.data?.token.refreshToken,
            userImage: '',
          },
        });
        if (rememberMe) {
          localStorage.setItem('user', JSON.stringify(res.data?.user));
          localStorage.setItem('token', res.data?.token.accessToken);
          localStorage.setItem('refreshToken', res.data?.token.refreshToken);
        } else {
          sessionStorage.setItem('user', JSON.stringify(res.data?.user));
          sessionStorage.setItem('token', res.data?.token.accessToken);
          sessionStorage.setItem('refreshToken', res.data?.token.refreshToken);
          localStorage.removeItem('user');
        }
        localStorage.setItem('rememberMe', rememberMe);
        toast.success('Successfully logged in!');
        navigate('/dashboard');
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          toast.info(err.response.data.message);
        }
      });
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/user/admin-login`, {
          email: user.email,
          password: user.password,
        })
        .then((res) => {
          dispatch({
            type: 'SET_DATA',
            payload: {
              isAuthenticated: true,
              user: res.data?.user,
              accessToken: res.data?.token.accessToken,
              refreshToken: res.data?.token.refreshToken,
              userImage: '',
            },
          });
          if (rememberMe) {
            localStorage.setItem('user', JSON.stringify(res.data?.user));
            localStorage.setItem('token', res.data?.token.accessToken);
            localStorage.setItem('refreshToken', res.data?.token.refreshToken);
          } else {
            sessionStorage.setItem('user', JSON.stringify(res.data?.user));
            sessionStorage.setItem('token', res.data?.token.accessToken);
            sessionStorage.setItem(
              'refreshToken',
              res.data?.token.refreshToken
            );
            localStorage.removeItem('user');
          }
          localStorage.setItem('rememberMe', rememberMe);
          toast.success('Successfully logged in!');
          navigate('/dashboard');
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.info(err.response.data.message);
          }
        });
    }
  };

  return (
    <Container maxWidth="xl">
      <Box className={styles.container}>
        <Box className={styles.main_container}>
          <Box className={styles.login_box}>
            <Typography variant="h4" align="center" className={styles.heading}>
              Login
            </Typography>
            <Typography variant="subtitle1" align="center">
              Please enter your login credentials
            </Typography>

            <form className={styles.form}>
              <InputWithLabel
                type="email"
                label="Email"
                name="email"
                value={user.email}
                placeholder="Email Address"
                handleChange={(e) =>
                  setUser({ ...user, email: e.target.value })
                }
              />
              <InputWithLabel
                type="password"
                label="Password"
                name="password"
                value={user.password}
                onKeyDown={handleKeyDown}
                placeholder="Enter Password"
                passwordResetLink={true}
                handleChange={(e) =>
                  setUser({ ...user, password: e.target.value })
                }
              />

              <FormControlLabel
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxOutlinedIcon />}
                    sx={{
                      color: 'black',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    }}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                }
                label="Remember Me"
              />

              <Button
                variant="contained"
                className={styles.login_btn}
                fullWidth
                onClick={handleLogin}
              >
                Login
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Pagination } from '@mui/material';
import DeletePlanModal from './DeletePlanModal';
import AppTable from '../common/AppTable';
import { planDecorator } from '../../utils/planDecorator';
import {
  companiesTableHeaders,
  freeUsersTableHeaders,
  paidUsersTableHeaders,
  adminUsersTableHeaders,
  invitedUsersTableHeaders,
  pageSize,
} from '../../utils/constants';
import styles from '../../styles/plans/planListing.module.css';
import { toast } from 'react-toastify';
import httpClient from '../../config/http.service';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { primary } from '../../constants/colors';

export const PlanListing = ({
  handleSortChange,
  plans,
  setPlans,
  update,
  setUpdate,
  sortDirectionMaps,
  setDirectionMaps,
  handleManage,
  type,
  handleResendInvite,
  page,
  setPage,
  totalPages
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [plansLoading, setPlansLoading] = useState(true);
  const [activeRow] = useState(null);
  // const [page, setPage] = useState(1);
  // const itemsPerPage = 8;
  // const totalPages = Math.ceil(plans?.length / itemsPerPage);
  // const startIndex = (page - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const displayedPlans = plans?.slice(startIndex, endIndex) ?? [];


  console.log(page, totalPages,'sp', pageSize)

  const handleActionsClose = () => setActionsAnchorEl(null);

  const handleDeletePlanModalClose = () => setShowDeletePlanModal(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (actionsAnchorEl && !actionsAnchorEl.contains(event.target)) {
        handleActionsClose();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [actionsAnchorEl]);

  useEffect(() => {
    getPlans();
  }, [update]);

  const getPlans = () => {
    if (plans?.length > 0) {
      const updatedPlans = plans.map((plan) => ({
        ...planDecorator(plan),
      }));
      setPlans(updatedPlans);
    }

    setTimeout(() => {
      setPlansLoading(false);
    }, 2000);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = () => {
    httpClient
      .delete('api/plan/delete', {
        data: {
          id: activeRow._id,
        },
      })
      .then(() => {
        const withoutRemoved = plans.filter(
          (item) => item._id !== activeRow?._id
        );
        setPlans(withoutRemoved);
        toast.success('Plan successfully deleted!');
        setShowDeletePlanModal(false);
        setUpdate(!update);
      })
      .catch((err) => {
        toast.info(err?.response?.data?.message);
      });
  };

  return (
    <Container maxWidth="xxl" className={styles.container}>
      <Box className={styles.table}>
        <AppTable
          data={plans ?? []}
          headers={
            type === 'paidUsers'
              ? paidUsersTableHeaders
              : type === 'freeUsers'
              ? freeUsersTableHeaders
              : type === 'adminUsers'
              ? adminUsersTableHeaders
              : type === 'invitedUsers' 
              ? invitedUsersTableHeaders
              : companiesTableHeaders
          }
          loading={plansLoading}
          handleSortChange={handleSortChange}
          sortDirectionMaps={sortDirectionMaps}
          setDirectionMaps={setDirectionMaps}
          handleManage={handleManage}
          handleResendInvite={handleResendInvite}
        />
        {plans?.length > 0 && (
          <Box sx={{ marginTop: '20px' }}>
            <Divider sx={internStyles.paginationDivider} />
            <Box style={internStyles.pagination_row}>
              {page > 1 ? (
                <Button
                  variant="outlined"
                  sx={{
                    color: 'black',
                    borderColor: '#EBEBEB',
                    marginRight: '20px',
                    backgroundColor: 'white',
                  }}
                  startIcon={<ArrowBackOutlinedIcon />}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </Button>
              ) : (
                <div style={{ width: '130px' }}></div>
              )}
              <Pagination
                // count={data.length}
                count={totalPages}
                page={page}
                hidePrevButton
                hideNextButton
                style={internStyles.pagination}
                // className={internStylesPlan.bgPagination}
                sx={{
                  '.Mui-selected': {
                    backgroundColor: `${primary} !important`,
                    color: 'white',
                  },
                }}
                onChange={handlePageChange}
              />
              {page < totalPages ? (
                <Button
                  variant="outlined"
                  sx={{
                    color: 'black',
                    borderColor: '#EBEBEB',
                    marginLeft: '20px',
                    backgroundColor: 'white',
                  }}
                  endIcon={<ArrowForwardOutlinedIcon />}
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </Button>
              ) : (
                <div style={{ width: '86px', marginLeft: '20px' }}></div>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <DeletePlanModal
        open={showDeletePlanModal}
        handleClose={handleDeletePlanModalClose}
        handleDelete={handleDelete}
      />
    </Container>
  );
};

const internStyles = {
  paginationDivider: { mt: '20px', border: '1px solid #E9ECEC' },
  paginationBox: { display: 'flex', justifyContent: 'center', mt: '20px' },
  pagination: {
    '.MuiSelected': {
      backgroundColor: `${primary} !important`,
      color: 'white',
    },
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
};

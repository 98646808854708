/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { PlanListing } from "../components/Plans/PlanListing";
import { useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/imgs/search-normal.svg";
import { black, porcelain, white } from "../constants/colors";
import { sortPlans } from "../utils/utils";
import { toast } from "react-toastify";
import { PLAN_TAB } from "../utils/enums";
import httpClient from "../config/http.service";
import { store } from "../Store";
import useDebounce from "../utils/hooks/useDebounce";
import { pageSize } from "../utils/constants";

export const InvitedUsers = () => {
  const { state } = useContext(store);
  const getActiveState = useLocation();
  const [active] = useState(getActiveState?.state?.active ?? "maps");
  const [users, setUsers] = useState(null);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstTimeUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [user, setUser] = useState(null);
  const [initialUsers, setInitialUsers] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesForSelect, setCompaniesForSelect] = useState(null);
  const [update, setUpdate] = useState(false);
  const [typeOfAction, setTypeOfAction] = useState("manage");
  const [selectedSortDirection, setSelectedSortDirection] = useState("asc");
  const [selectedSortOption, setSelectedSortOption] = useState("companyName");

  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderAfterPageChange, setRenderAfterPageChange] = useState(true);

  useEffect(() => {
    if (searchTerm !== "") {
      getUsers(searchTerm);
      setRenderAfterPageChange(false);
      setPageNumber(1);
    } else {
      getUsers();
      setRenderAfterPageChange(true);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (state?.data?.user && renderAfterPageChange) {
      getUsers();
    }
  }, [state?.data, update, pageNumber]);

  // const getCompanyPhoto = (companyId) => {
  //   return new Promise((resolve, reject) => {
  //     httpClient
  //       .get(`api/file/get/${companyId}?width=64&height=64`)
  //       .then((res) => {
  //         const imageData = new Uint8Array(res.data);
  //         const blob = new Blob([imageData], { type: "image/jpeg" });
  //         const imageUrl = URL.createObjectURL(blob);
  //         resolve(imageUrl);
  //       })
  //       .catch((err) => {
  //         if (err?.response?.data?.code !== 400)
  //           toast.info(err?.response?.data?.message);
  //         reject(err);
  //       });
  //   });
  // };

  const getCompany = (companyId) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId, avatar: true, width: 64, height: 64  } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.code !== 400)
            toast.info(err?.response?.data?.message);
          reject(err);
        });
    });
  };

  const getUsers = async (name = "") => {
    try {
      const locPageNumber = !name ? pageNumber : 1;
      const res = await httpClient.get(`api/user/invited-users`, {
        params: {
          pageNumber: locPageNumber,
          pageSize,
          name,
        },
      });
      const valueToString = res.data.users;
      setTotalPages(res.data.totalPages ?? 0);
      let newUsers = [];

      for (let el of valueToString) {
        const company = await getCompany(el.company);
        let obj = {};

        if (company.hasAvatar && company.logo) {
          const imageData = new Uint8Array(company.logo);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          obj.logo = imageUrl;
        } 

        obj._id = el._id + "";
        obj.username = el.firstName + " " + el.lastName;
        obj.companyName = company.name;
        obj.email = el.email;
        obj.jobTitle = el.jobTitle;
        obj.paid = el.paid;
        obj.tokenToSend = el.tokenToSend;

        newUsers.push(obj);
      }
      setUsers(newUsers);
    } catch (err) {
      toast.info(err?.response?.data?.message);
    }
  };

  const getCompanies = () => {
    httpClient
      .get(`api/company/all`)
      .then((res) => {
        let arrayOfCompanies = [];
        res.data.map((el) => {
          const option = { value: el._id, label: el.name };
          arrayOfCompanies.push(option);
        });
        setCompaniesForSelect(arrayOfCompanies);
        setCompanies(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 400)
          toast.info(err?.response?.data?.message);
      });
  };

  const handleSearch = (value) => setSearchTerm(value);

  const onClickRow = (obj, index) => {
    setSelectedRow(obj);
  };

  const handleSortChange = (item) => {
    if (active === PLAN_TAB.maps) {
      setSelectedSortOption(item);
      setSelectedSortDirection(
        selectedSortDirection === "asc" ? "desc" : "asc"
      );
      const sortedArray = sortPlans(
        item,
        users,
        selectedSortDirection === "asc" ? "desc" : "asc"
      );
      setUsers(sortedArray);
    }
  };

  const handleResendInvite = (e, single) => {
    console.log(single.email, single.username, "aaa__");
    httpClient
      .post(`api/user/resend-user-invite`, {
        email: single?.email,
        username: single?.username,
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 400)
          toast.info(err?.response?.data?.message);
      });
  };

  return (
    <Box sx={styles.main}>
      {!firstTimeUser ? (
        <Box sx={{ padding: "23px 16px" }}>
          <Container maxWidth="xxl">
            <Grid container sx={styles.tabs}>
              <Grid item sx={styles.tabsItem}>
                <Typography sx={styles.title}>Invited Users</Typography>
              </Grid>
              <Grid item sx={styles.header_btns}>
                <Input
                  id="search"
                  placeholder="Search by name"
                  sx={styles.search_input}
                  onChange={(event) => handleSearch(event.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ marginLeft: "17.5px" }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Container>
          <PlanListing
            type="invitedUsers"
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onClickRow={onClickRow}
            active={active}
            plans={users}
            setPlans={setUsers}
            update={update}
            setUpdate={setUpdate}
            handleSortChange={handleSortChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
            handleManage={(e, single) => {
              setUser(single);
              setTypeOfAction("manage");
              setOpenManageModal(true);
            }}
            handleResendInvite={handleResendInvite}
            page={pageNumber}
            setPage={setPageNumber}
            totalPages={totalPages}
          />
        </Box>
      ) : (
        <Box>Test</Box>
      )}
    </Box>
  );
};

const styles = {
  main: {
    backgroundColor: `${porcelain} !important`,
    minHeight: "100vh !important",
  },
  header: {
    display: "flex !important",
    justifyContent: "space-between !important",
    flexWrap: "wrap !important",
    alignItems: "center",
    marginBottom: "25px",
  },
  search_input: {
    backgroundColor: white,
    width: "250px",
    height: "40px",
    border: "1px solid #F4F4F4",
    borderRadius: "32px",
    fontSize: "14px",
    marginRight: "12px",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingVertical: "20px",
    marginBottom: "20px",
    padding: "9px",
    "@media screen and (min-width: 500px)": {
      justifyContent: "space-between",
    },
  },
  tabsItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    "@media screen and (min-width: 500px)": {
      flexDirection: "row",
    },
    "@media screen and (min-width: 1150px)": {
      marginBottom: "0px",
    },
  },
  title: {
    color: black,
    fontSize: "18px",
    fontFamily: "SoehneBold",
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0",
    background: white,
    borderRadius: "8px",
    padding: "10px 14px",
    gap: "8px",
    "@media screen and (min-width: 500px)": {
      marginLeft: "20px",
    },
  },
  header_btns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginLeft: "20px",
    "@media screen and (min-width: 500px)": {
      marginLeft: "0",
    },
  },
  add_btn: {
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "24px",
    height: "40px",
    padding: "10px 20px",
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "transparent",
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    fontFamily: "SoehneBold",
  },
};

module.exports.PLAN_ACCESS = {
  name: 'name',
  count: 'count',
  boolean: 'boolean',
  status: 'status',
  lastViewed: 'lastViewed',
  userType: 'userType',
  Others: 'Others',
  Vendor: 'Vendor',
};

module.exports.PLAN_TAB = {
  maps: 'maps',
  share: 'share',
  archived: 'archived',
};

module.exports.SETTINGS_TAB = {
  freeUsers: 'freeUsers',
  paidUsers: 'paidUsers',
  companies: 'companies',
  inivitedUsers: "inivitedUsers",
  adminUsers: 'adminUsers',
};

module.exports.PLAN_FIELDS = {
  status: 'status',
  completed: 'completed',
  duration: 'duration',
  map: 'map',
  engangement: 'engangement',
  access: 'access',
  customerCompany: 'customerCompany',
  custom: 'custom',
  vendor: 'vendor',
};

module.exports.TEMPLATES_TAB = {
  myTemplates: 'myTemplates',
  shared: 'shared',
};

module.exports.SORT_DIRECTION = {
  asc: 'asc',
  desc: 'desc',
};

module.exports.TIMELINE = {
  added: 'added',
  shared: 'shared',
  deleted: 'deleted',
  modified: 'modified',
  text: 'text',
};

import React, { useCallback, useContext, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedCanvas, getCroppedImg } from './canvasUtils';
import { Button, Select, Slider } from '@mui/material';
import httpClient from '../config/http.service';
import { showNotification } from '../components/common/Toast';
import { store } from '../Store';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { black, mercury, primary, white } from '../constants/colors';

const aspectRatios = [
  { value: 1 / 1, text: '1/1' },
  { value: 4 / 3, text: '4/3' },
  { value: 16 / 9, text: '16/9' },
  { value: 1 / 2, text: '1/2' },
];

export function getFileFromImage(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const ResizeImageSettings = ({
  imageSrc,
  cancelResizing,
  setProfilePhoto,
  setProfilePhotoDetails,
  setCompanyPhoto,
  setCompanyPhotoDetails,
  data,
  imageSrcCompany,
  typeOfImage,
  company,
  setData,
  aspectInit,
}) => {
  const { state, dispatch } = useContext(store);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  if (aspectInit == null) {
    aspectInit = aspectRatios[0];
  }
  const [aspect, setAspect] = useState(aspectInit);

  const showCroppedImage = useCallback(async () => {
    if (typeOfImage === 'user' && imageSrc) {
      if (data?._id) {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          );

          const getCanvas = await getCroppedCanvas(
            imageSrc,
            croppedAreaPixels,
            rotation
          );
          const getFile = getFileFromImage(getCanvas, 'profileimage');
          setProfilePhoto(croppedImage);
          setProfilePhotoDetails(getFile);

          const newFile = new FormData();
          const newName = new File([getFile], 'profileimage2', {
            type: getFile.type,
          });
          newFile.append('image', newName);
          newFile.append('user_id', data._id);

          httpClient
            .post('api/user/add-avatar', newFile)
            .then((res) => {
              dispatch({
                type: 'SET_DATA',
                payload: {
                  isAuthenticated: true,
                  user: res.data,
                  accessToken: state.data.accessToken,
                  userImage: URL.createObjectURL(newName),
                },
              });
              setData(res.data);
              const newUser = { ...res.data };
              newUser.hasAvatar = true;
              const rememberMe = localStorage.getItem('rememberMe');
              if (rememberMe == 'true') {
                localStorage.setItem('user', JSON.stringify(res.data));
              } else {
                sessionStorage.setItem('user', JSON.stringify(res.data));
              }
            })
            .catch((err) =>
              showNotification('error', err?.response?.data?.message)
            );
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          );

          const getCanvas = await getCroppedCanvas(
            imageSrc,
            croppedAreaPixels,
            rotation
          );
          const getFile = getFileFromImage(getCanvas, 'profile');
          setProfilePhoto(croppedImage);
          setProfilePhotoDetails(getFile);
        } catch (e) {
          console.log(e);
        }
      }
    } else if (typeOfImage === 'company' && imageSrcCompany) {
      if (company?._id) {
        try {
          const croppedImage = await getCroppedImg(
            imageSrcCompany,
            croppedAreaPixels,
            rotation
          );

          const getCanvas = await getCroppedCanvas(
            imageSrcCompany,
            croppedAreaPixels,
            rotation
          );
          const getFile = getFileFromImage(getCanvas, 'customerimage');
          setCompanyPhoto(croppedImage);
          setCompanyPhotoDetails(getFile);

          const newFile = new FormData();
          const newName = new File([getFile], 'customerimage2', {
            type: getFile.type,
          });
          newFile.append('image', newName);
          newFile.append('company_id', company._id);

          httpClient
            .post('api/company/add-avatar', newFile)
            .then((res) => {
              console.log('RES DATA', res.data);
            })
            .catch((err) =>
              showNotification('error', err?.response?.data?.message)
            );
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const croppedImage = await getCroppedImg(
            imageSrcCompany,
            croppedAreaPixels,
            rotation
          );

          const getCanvas = await getCroppedCanvas(
            imageSrcCompany,
            croppedAreaPixels,
            rotation
          );
          const getFile = getFileFromImage(getCanvas, 'customerimage');
          setCompanyPhoto(croppedImage);
          setCompanyPhotoDetails(getFile);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [imageSrc, imageSrcCompany, croppedAreaPixels, rotation]);

  const onAspectChange = (e) => {
    const value = e.target.value;
    const ratio = aspectRatios.find((ratio) => ratio.value == value);
    setAspect(ratio);
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'relative',
          width: '100%',
          minHeight: '400px',
          objectFit: 'contain',
        }}
      >
        <Cropper
          image={typeOfImage === 'user' ? imageSrc : imageSrcCompany}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspect.value}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{
            containerStyle: {
              backgroundColor: 'transparent',
            },
            cropAreaStyle: {
              borderRadius: typeOfImage === 'company' ? '6px' : '50%',
            },
          }}
        />
      </div>
      <div>
        <div>
          <Typography
            style={{
              fontFamily: 'InterBold',
              fontStyle: 'normal',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              color: black,
            }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: 'InterBold',
              fontStyle: 'normal',
              fontSize: '12px',
              lineHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              color: black,
            }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={0.1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Button
              onClick={() => cancelResizing(typeOfImage)}
              type="outlined"
              style={{
                color: black,
                padding: '8px 16px',
                background: mercury,
                borderRadius: '100px',
                boxShadow: 'none',
                border: `1px solid ${mercury}`,
                '&:hover': { background: mercury },
                textTransform: 'unset',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={showCroppedImage}
              type="contained"
              style={{
                color: white,
                padding: '8px 16px',
                // background: 'rgba(2, 17, 17, 0.25)',
                background: primary,
                borderRadius: '100px',
                boxShadow: 'none',
                fontWeight: 'bold',
                fontFamily: 'SoehneBold',
                // '&:hover': { background: primary },
                textTransform: 'unset',
              }}
            >
              Save
            </Button>
          </Box>
          <Select
            value={aspect.value}
            name="aspect"
            onChange={onAspectChange}
            style={{
              backgroundColor: 'rgb(250, 250, 250)',
              height: '40px',
              marginRight: '24px',
            }}
          >
            {aspectRatios.map((ratio) => (
              <MenuItem
                key={ratio.value}
                value={ratio.value}
                style={{ color: 'black' }}
              >
                {ratio?.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </React.Fragment>
  );
};

export const styles = (theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    backgroundColor: 'transparent',
    borderRadius: '100px',
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
  },
});

import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormHelperText } from '@mui/material';
import { gallery } from '../../constants/colors';

export const AppDatePicker = ({
  name,
  value,
  disabled,
  disablePast = false,
  handleChange,
  slotPropsStyles,
  inputStyles,
  helperText,
}) => {
  return (
    <>
      <DesktopDatePicker
        value={value ? dayjs(value) : null}
        format="DD MMMM, YYYY"
        disabled={disabled}
        sx={{
          width: '100%',
          marginBottom: '8px',
          '& .MuiInputBase-root': {
            height: '40px',
            fontSize: '14px',
            ...inputStyles,
          },
          '& .Mui-focused fieldset': { border: '1px solid black !important' },
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${gallery} !important`,
          },
        }}
        disablePast={disablePast}
        onChange={(newValue) =>
          handleChange({ target: { name, value: newValue } })
        }
        slotProps={{
          openPickerIcon: { sx: { fontSize: '18px' } },
          textField: { placeholder: 'Enter date' },
          ...slotPropsStyles,
        }}
      />
      {helperText && (
        <FormHelperText sx={{ fontSize: '12px' }}>{helperText}</FormHelperText>
      )}
    </>
  );
};

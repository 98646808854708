import React from 'react';
import { Box, Grid } from '@mui/material';
import { porcelain, white } from '../constants/colors';
import AdminPanelHeader from '../pages/admin/Headers/headers';

const styles = {
  main: {
    backgroundColor: `${porcelain} !important`,
    minHeight: '100vh !important',
  },
};

const withDashboardHeader = (Component) => {
  return () => (
    <Box sx={styles.main}>
      <AdminPanelHeader />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={8}
          sx={{ margin: '50px', padding: 0, backgroundColor: white }}
        >
          <Component />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withDashboardHeader;

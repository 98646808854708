import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import { black, mercury, warning } from '../constants/colors';

export const DisableUser = ({
  type,
  open,
  handleClose,
  handleSubmit,
  user,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {type === 'disable'
          ? user?.disabled
            ? 'Enable User'
            : 'Disable User'
          : 'Delete User'}
      </Typography>
      <Typography variant="h5" sx={styles.body}>
        {type === 'disable'
          ? 'Are you sure you want to disable this user? They won’t have access to Inquarter anymore.'
          : 'Are you sure you want to delete this user?'}
      </Typography>
      <Box sx={styles.btns}>
        <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" sx={styles.delete} onClick={handleSubmit}>
          {type === 'disable'
            ? user?.disabled
              ? 'Enable User'
              : 'Disable User'
            : 'Delete User'}
        </Button>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '410px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,
  },
  heading: {
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '12px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    '&:hover': { background: mercury },
  },
  delete: {
    color: 'white',
    minWidth: '115px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    background: warning,
    '&:hover': { background: warning },
  },
};

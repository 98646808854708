/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import { PlanListing } from '../components/Plans/PlanListing';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../assets/imgs/search-normal.svg';
import { black, porcelain, white } from '../constants/colors';
import { formatToTableDate, sortPlans } from '../utils/utils';
import { toast } from 'react-toastify';
import { PLAN_TAB } from '../utils/enums';
import httpClient from '../config/http.service';
import { store } from '../Store';
import { ManageUserModal } from '../modals/ManageUserModal';
import { DisableUser } from '../modals/DisableUser';
import useDebounce from '../utils/hooks/useDebounce';
import { pageSize } from '../utils/constants';

const tiers = [
  { label: 'Teams', value: 'Teams' },
  { label: 'Individual', value: 'Individual' },
];

const language = [{ label: 'EN', value: 'EN' }];

const hosted = [
  { label: 'EU', value: 'EU' },
  { label: 'US', value: 'US' },
];

export const FreeUsers = () => {
  const { state } = useContext(store);
  const getActiveState = useLocation();
  const [active] = useState(getActiveState?.state?.active ?? 'maps');
  const [users, setUsers] = useState(null);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [firstTimeUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [typeForAction, setTypeForAction] = useState('');
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesForSelect, setCompaniesForSelect] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateTemplates, setUpdateTemplates] = useState(false);
  const [typeOfAction, setTypeOfAction] = useState('manage');
  const [selectedSortDirection, setSelectedSortDirection] = useState('asc');
  const [selectedSortOption, setSelectedSortOption] = useState('companyName');
  const [templates, setTemplates] = useState([]);

  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1);
  const [renderAfterPageChange, setRenderAfterPageChange] = useState(true)

  useEffect(() => {
    if (searchTerm !== '') {
      getUsers(searchTerm);
      setRenderAfterPageChange(false)
      setPageNumber(1)
    } else {
      getUsers()
      setRenderAfterPageChange(true)
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getCompanies();
    getCurrencies();
  }, []);

  useEffect(() => {
    getTemplates();
  }, [updateTemplates]);

  useEffect(() => {
    if (state?.data?.user && renderAfterPageChange) {
      getUsers();
    }
  }, [state?.data, update, pageNumber]);

  // const getCompanyPhoto = (companyId) => {
  //   return new Promise((resolve, reject) => {
  //     httpClient
  //       .get(`api/file/get/${companyId}?width=64&height=64`)
  //       .then((res) => {
  //         const imageData = new Uint8Array(res.data);
  //         const blob = new Blob([imageData], { type: 'image/jpeg' });
  //         const imageUrl = URL.createObjectURL(blob);
  //         resolve(imageUrl);
  //       })
  //       .catch((err) => {
  //         if (err?.response?.data?.code !== 400)
  //           toast.info(err?.response?.data?.message);
  //         reject(err);
  //       });
  //   });
  // };

  const getCompany = (companyId) => {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`api/company/get`, { params: { _id: companyId, avatar: true, width: 64, height: 64   } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err?.response?.data?.code !== 400)
            toast.info(err?.response?.data?.message);
          reject(err);
        });
    });
  };

  const getUsers = async (name = '') => {
    try {
      const locPageNumber = !name ? pageNumber : 1;
      const res = await httpClient.get(`api/user/all-users`, {
        params: { paid: false, pageNumber: locPageNumber, pageSize, name },
      });
      const valueToString = res.data.users;
      setTotalPages(res.data.totalPages ?? 0)
      let newUsers = [];

      for (let el of valueToString) {
        const company = await getCompany(el.company);
        let obj = {};
        obj._id = el._id + '';
        obj.username = el.fullName;
        obj.company = el.company;

        if (company.hasAvatar && company.logo) {
          const imageData = new Uint8Array(company.logo);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          obj.logo = imageUrl;
        } 

        obj.companyName = company.name || '';
        obj.companyId = company._id;
        obj.tier = el.tier;
        obj.teamname = el.teamName;
        obj.numberOfPlans = el.numberOfPlans || 0;
        obj.lastLogin =
          formatToTableDate(el.lastLogin) !== 'Invalid Date'
            ? formatToTableDate(el.lastLogin)
            : 'Not yet logged in';
        obj.disabled = el.disabled;
        obj.email = el.email;
        obj.deleted = el.deleted;
        obj.firstName = el.firstName;
        obj.lastName = el.lastName;
        obj.jobTitle = el.jobTitle;
        obj.hosted = el.hosted;
        obj.language = el.language;
        obj.paid = el.paid;
        obj.mainContactEmail = el.mainContactEmail;
        obj.mainContactName = el.mainContactName;
        obj.defaultCurrency = el.defaultCurrency;
        obj.renewalDate =
          formatToTableDate(el.renewalDate) !== 'Invalid Date'
            ? formatToTableDate(el.renewalDate)
            : '';
        obj.canUpdateCompany = el.canUpdateCompany;
        obj.templateId = el.defaultTemplateId || '';
        newUsers.push(obj);
      }
      setUsers(newUsers);
    } catch (err) {
      toast.info(err?.response?.data?.message);
    }
  };

  const getCompanies = () => {
    httpClient
      .get(`api/company/all`)
      .then((res) => {
        let arrayOfCompanies = [];
        res.data.map((el) => {
          const option = { value: el._id, label: el.name };
          arrayOfCompanies.push(option);
        });
        setCompaniesForSelect(arrayOfCompanies);
        setCompanies(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 400)
          toast.info(err?.response?.data?.message);
      });
  };

  const getCurrencies = () => {
    httpClient
      .get('api/currency/all')
      .then((res) => {
        setCurrencies(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code !== 400)
          toast.info(err?.response?.data?.message);
      });
  };

  const getTemplates = () => {
    httpClient
      .get(`api/template/all`, { params: { _id: state?.data?.user } })
      .then((res) => {
        let arrayOfTemplates = [];
        res.data.map((el) => {
          const option = { value: el._id, label: el.name };
          arrayOfTemplates.push(option);
        });
        setTemplates(arrayOfTemplates);
      })
      .catch((err) => {
        // if (err?.response?.data?.code !== 400)
        //   toast.info(err?.response?.data?.message);
      });
  };

  // const getUsersWithSearch = () => {
  //   const filteredUsers = initialUsers.filter((user) =>
  //     user.username.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  //   setUsers(filteredUsers);
  // };

  const handleUserDetails = (e) => {
    const { name, value } = e.target;
    if (name === 'companyId') {
      const findCompany = companiesForSelect.find((el) => el.value === value);
      setUser({
        ...user,
        companyName: findCompany.label,
        companyId: value,
      });
    } else if (name === 'template') {
      const findCompany = templates.find((el) => el.value === value);
      setUser({
        ...user,
        templateName: findCompany.label,
        templateId: value,
      });
    } else setUser({ ...user, [name]: value });
  };

  const handleUpdateUser = () => {
    if (user.paid) {
      httpClient
        .patch('api/user/admin-update-user', {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          teamName: user.teamname,
          company: user.companyId,
          jobTitle: user.jobTitle,
          language: user.language,
          hosted: user.hosted,
          paid: user.paid,
          mainContactEmail: user.mainContactEmail || '',
          mainContactName: user.mainContactName || '',
          defaultTemplateId: user.templateId || '',
          renewalDate: user.renewalDate || '',
          canUpdateCompany: user.canUpdateCompany,
          defaultCurrency: user.defaultCurrency || '',
        })
        .then(() => {
          toast.success(`Successfully updated user!`);
          setOpenActionModal(false);
          setOpenManageModal(false);
          setUser(null);
          setUpdate(!update);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      httpClient
        .patch('api/user/admin-update-user', {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          company: user.companyId,
          jobTitle: user.jobTitle,
          language: user.language,
          hosted: user.hosted,
          defaultTemplateId: user.templateId || '',
          paid: user.paid,
          canUpdateCompany: user.canUpdateCompany,
          defaultCurrency: user.defaultCurrency || '',
        })
        .then(() => {
          toast.success(`Successfully updated user!`);
          setOpenActionModal(false);
          setOpenManageModal(false);
          setUser(null);
          setUpdate(!update);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    }
  };

  const handleAddUser = () => {
    if (user.paid) {
      httpClient
        .post('api/user/admin-invite-user', {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          teamName: user.teamname,
          company: user.companyName,
          jobTitle: user.jobTitle,
          language: user.language,
          hosted: user.hosted,
          paid: user.paid,
          mainContactEmail: user.mainContactEmail || '',
          mainContactName: user.mainContactName || '',
          renewalDate: user.renewalDate || '',
          defaultTemplateId: user.templateId || '',
          canUpdateCompany: user.canUpdateCompany,
          defaultCurrency: user.defaultCurrency || '',
        })
        .then(() => {
          toast.success(`Successfully invited user!`);
          setOpenActionModal(false);
          setOpenManageModal(false);
          setUpdate(!update);
          setUser(null);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      httpClient
        .post('api/user/admin-invite-user', {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          company: user.companyName,
          jobTitle: user.jobTitle,
          language: user.language,
          hosted: user.hosted,
          paid: user.paid,
          defaultTemplateId: user.templateId || '',
          canUpdateCompany: user.canUpdateCompany,
          defaultCurrency: user.defaultCurrency || '',
        })
        .then(() => {
          toast.success(`Successfully invited user!`);
          setOpenActionModal(false);
          setOpenManageModal(false);
          setUpdate(!update);
          setUser(null);
        })
        .catch((err) => toast.info(err?.response?.data?.message));
    }
  };

  const handleSearch = (value) => setSearchTerm(value);

  const onClickRow = (obj, index) => {
    setSelectedRow(obj);
  };

  const handleSortChange = (item) => {
    if (active === PLAN_TAB.maps) {
      setSelectedSortOption(item);
      setSelectedSortDirection(
        selectedSortDirection === 'asc' ? 'desc' : 'asc'
      );
      const sortedArray = sortPlans(
        item,
        users,
        selectedSortDirection === 'asc' ? 'desc' : 'asc'
      );
      setUsers(sortedArray);
    }
  };

  const handleDisable = () => {
    const key = typeForAction === 'disable' ? 'disabled' : 'deleted';
    const bool = !user[key];

    if(typeForAction === 'delete'){
      httpClient
      .delete(`api/user/admin-delete-user?_id=${user._id}`)
      .then(() => {
        toast.success(
          `Successfully deleted user!`
        );
        setOpenActionModal(false);
        setUser(null);
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
    } else {
      httpClient
      .patch('api/user/admin-update-user', { _id: user._id, [key]: bool })
      .then(() => {
        toast.success(
          `Successfully ${
            typeForAction === 'disable'
              ? user.disabled
                ? 'enabled'
                : 'disabled'
              : 'deleted'
          } user!`
        );
        setOpenActionModal(false);
        setUser(null);
        setUpdate(!update);
      })
      .catch((err) => toast.info(err?.response?.data?.message));
    }
  };

  const handleCheckbox = (e, name) => {
    if (e.target.checked) {
      setUser({ ...user, [name]: e.target.checked });
    } else {
      setUser({
        ...user,
        teamname: '',
        mainContactEmail: '',
        mainContactName: '',
        renewalDate: '',
        [name]: e.target.checked,
      });
    }
  };

  const handleTeamname = (e) => {
    setUser({ ...user, teamname: e.target.value });
  };

  return (
    <Box sx={styles.main}>
      {!firstTimeUser ? (
        <Box sx={{ padding: '23px 16px' }}>
          <Container maxWidth="xxl">
            <Grid container sx={styles.tabs}>
              <Grid item sx={styles.tabsItem}>
                <Typography sx={styles.title}>Free Users</Typography>
              </Grid>
              <Grid item sx={styles.header_btns}>
                <Input
                  id="search"
                  placeholder="Search by User name"
                  sx={styles.search_input}
                  onChange={(event) => handleSearch(event.target.value)}
                  disableUnderline
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ marginLeft: '17.5px' }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                <Button
                  variant="contained"
                  sx={styles.add_btn}
                  onClick={() => {
                    setOpenManageModal(true);
                    setTypeOfAction('add');
                  }}
                >
                  Add User
                  <AddCircleOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          </Container>
          <PlanListing
            type="freeUsers"
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onClickRow={onClickRow}
            active={active}
            plans={users}
            setPlans={setUsers}
            update={update}
            setUpdate={setUpdate}
            handleSortChange={handleSortChange}
            sortDirectionMaps={selectedSortDirection}
            setDirectionMaps={setSelectedSortDirection}
            handleManage={(e, single) => {
              setUser(single);
              setTypeOfAction('manage');
              setOpenManageModal(true);
            }}
            page={pageNumber}
            setPage={setPageNumber}
            totalPages={totalPages}
          />
          <ManageUserModal
            templates={templates}
            user={user}
            currenciesForSelect={currencies}
            companiesForSelect={companiesForSelect}
            companies={companies}
            tiers={tiers}
            language={language}
            hosted={hosted}
            handleTeamname={handleTeamname}
            handleUserDetails={handleUserDetails}
            handleUpdate={handleUpdateUser}
            handleAdd={handleAddUser}
            handleCheckbox={handleCheckbox}
            openDisableModal={() => {
              setOpenManageModal(false);
              setOpenActionModal(true);
              setTypeForAction('disable');
            }}
            openDeleteUserModal={() => {
              setOpenManageModal(false);
              setOpenActionModal(true);
              setTypeForAction('delete');
            }}
            open={openManageModal}
            handleClose={() => {
              setUser(null);
              setOpenManageModal(false);
            }}
            type={typeOfAction}
            updateTemplates={updateTemplates}
            setUpdateTemplates={setUpdateTemplates}
          />
          {user && (
            <DisableUser
              open={openActionModal}
              type={typeForAction}
              handleClose={() => setOpenActionModal(false)}
              handleSubmit={handleDisable}
              user={user}
            />
          )}
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};

const styles = {
  main: {
    backgroundColor: `${porcelain} !important`,
    minHeight: '100vh !important',
  },
  header: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexWrap: 'wrap !important',
    alignItems: 'center',
    marginBottom: '25px',
  },
  search_input: {
    backgroundColor: white,
    width: '250px',
    height: '40px',
    border: '1px solid #F4F4F4',
    borderRadius: '32px',
    fontSize: '14px',
    marginRight: '12px',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: '20px',
    marginBottom: '20px',
    padding: '9px',
    '@media screen and (min-width: 500px)': {
      justifyContent: 'space-between',
    },
  },
  tabsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    '@media screen and (min-width: 500px)': {
      flexDirection: 'row',
    },
    '@media screen and (min-width: 1150px)': {
      marginBottom: '0px',
    },
  },
  title: {
    color: black,
    fontSize: '18px',
    fontFamily: 'SoehneBold',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0',
    background: white,
    borderRadius: '8px',
    padding: '10px 14px',
    gap: '8px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '20px',
    },
  },
  header_btns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginLeft: '20px',
    '@media screen and (min-width: 500px)': {
      marginLeft: '0',
    },
  },
  menu: {
    borderRadius: 8,
  },
  add_btn: {
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '24px',
    height: '40px',
    padding: '10px 20px',
    shadowOffset: { width: 0, height: 0 },
    shadowColor: 'transparent',
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    fontFamily: 'SoehneBold',
  },
};

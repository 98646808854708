import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  Drawer,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Logo } from '../../../assets';
import styles from './headers.module.css';
import { useEffect } from 'react';
import { store } from '../../../Store';
import { getInitials } from '../../../utils/utils';
import httpClient from '../../../config/http.service';
import SearchModal from '../../../components/common/SearchModal';
import { toast } from 'react-toastify';

const menuOptions = [{ name: 'Profile settings', type: 'profile' }];

const AdminPanelHeader = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(store);
  const [user, setUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState(
    state?.data?.userImage ? state.data.userImage : null
  );
  const [initial, setInitial] = useState(state?.data?.userImage || null);
  const getUserPhoto = async (id) => {
    if (id) {
      httpClient
        .get(`api/file/get/${id}`)
        .then((res) => {
          const imageData = new Uint8Array(res.data);
          const blob = new Blob([imageData], { type: 'image/jpeg' });
          const imageUrl = URL.createObjectURL(blob);
          setProfilePhoto(imageUrl);
        })
        .catch((err) => {
          if (err?.response?.data?.code !== 400)
            toast.info(err?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    const storedRememberMe = localStorage.getItem('rememberMe');
    setRememberMe(storedRememberMe === 'true');
    setUser(state?.data?.user);
    setLoading(false);
  }, [state?.data?.user]);

  useEffect(() => {
    if (initial !== state?.data?.userImage)
      if (state?.data?.user?.hasAvatar) getUserPhoto(state.data.user._id);
      else setProfilePhoto(null);
  }, [state?.data?.userImage]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    navigate('/dashboard');
  };
  const handleSearchModalClose = () => setShowSearchModal(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const drawer = (
    <Box onClick={handleDrawerToggle} textAlign="center">
      <img className={styles.drawer_icon} src={Logo} alt="Logo" />
      <Divider />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem('token');
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('token');
    // if (!rememberMe) {
    //   localStorage.removeItem('user');
    // }

    setAnchorEl(null);
    navigate('/login');
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Box className={styles.main}>
        <AppBar component="nav" className={styles.app_bar}>
          <Container maxWidth="xxl">
            <Toolbar className={styles.toolbar}>
              <Grid container spacing={2}>
                <Grid item xs={2} className={styles.toolbar_content_logo}>
                  <Link to="/dashboard">
                    <Typography
                      variant="h6"
                      component="div"
                      className={styles.logo}
                    >
                      <img src={Logo} alt="Logo" width="90%" />
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={6} className={styles.toolbar_content}>
                  <Typography variant="h6" className={styles.hello}>
                    Hello, {user.fullName ?? ''} &#128512;
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box className={styles.configrations}>
                    <Box className={styles.profile_btn} onClick={handleClick}>
                      <Box className={styles.profile_btn_content}>
                        <Avatar
                          className={styles.profile_avatar}
                          src={profilePhoto}
                        >
                          {user
                            ? getInitials(user?.firstName, user?.lastName)
                            : 'BS'}
                        </Avatar>
                        <Box>
                          <Typography variant="body1" className={styles.user}>
                            {user
                              ? user.firstName + ' ' + user.lastName
                              : 'Ben Shevlane'}
                          </Typography>
                          <Typography className={styles.role}>
                            {user.roles?.includes('super_admin')
                              ? 'Super admin'
                              : 'Admin'}
                          </Typography>
                        </Box>
                      </Box>

                      <ExpandMoreIcon />
                    </Box>
                    <Menu
                      open={open}
                      id="profile-button"
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      PaperProps={{
                        className: styles.menu,
                        sx: { width: anchorEl && anchorEl.offsetWidth },
                      }}
                      MenuListProps={{ 'aria-labelledby': 'profile-button' }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                      {menuOptions.map((item) => (
                        <MenuItem
                          key={item.name}
                          disableTouchRipple
                          className={styles.menu_item}
                          onClick={() => {
                            handleClose(item);
                            navigate('/profile');
                          }}
                        >
                          <span>{item.name}</span>
                        </MenuItem>
                      ))}
                      <MenuItem
                        disableTouchRipple
                        className={styles.logout_btn}
                        onClick={handleLogout}
                      >
                        <span>Log out</span>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Drawer for mobile screen */}
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className={styles.drawer}
            PaperProps={{ className: styles.drawer_paper }}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <Toolbar />

      <SearchModal
        open={showSearchModal}
        handleClose={handleSearchModalClose}
      />
    </>
  );
};

export default AdminPanelHeader;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  tableContainerClasses,
  tableClasses,
  Skeleton,
  Box,
  Typography,
} from '@mui/material';
import { NoDataImage } from '../../assets';
import { styled } from '@mui/material/styles';
import { randomColor } from '../../utils/utils';
import AvatarImage from '../../assets/imgs/avatar.png';
import { black, primary, text, white } from '../../constants/colors';
import { toast } from 'react-toastify';

const AppTable = ({
  headers,
  data = [],
  loading,
  handleManage,
  handleSortChange,
  handleResendInvite
}) => {
  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (!colors) {
      const arrayOfColors = [];
      for (let i = 0; i < data.length; i++) {
        arrayOfColors.push(randomColor());
      }
      setColors(arrayOfColors);
    }
  }, [data]);

  const handleCopy = (token) => {
    const link = `${process.env.REACT_APP_WEB}create-password?token=${token}`;
    navigator.clipboard.writeText(link);
    toast.info('Succesfully copied link!')
  }

  return (
    <>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell
                  key={header.key + index}
                  align={header.align ? header.align : 'left'}
                  sx={{
                    minWidth: header.minWidth ? header.minWidth : 'auto',
                    fontWeight: 'bold',
                    cursor:
                      header.key === 'title' ||
                      header.key === 'companyName' ||
                      header.key === 'teamname' ||
                      header.key === 'totalTeams' ||
                      header.key === 'totalUsers' ||
                      header.key === 'numberOfPlans' ||
                      header.key === 'mainContactName' ||
                      header.key === 'mainContactEmail'
                        ? 'pointer'
                        : 'indicator',
                    marginLeft:
                      header.key === 'title' || header.key === 'name'
                        ? '10px'
                        : '0',
                  }}
                  onClick={() => {
                    if (
                      header.key === 'title' ||
                      header.key === 'companyName' ||
                      header.key === 'teamname' ||
                      header.key === 'totalTeams' ||
                      header.key === 'numberOfPlans' ||
                      header.key === 'totalUsers' ||
                      header.key === 'mainContactName' ||
                      header.key === 'mainContactEmail'
                    )
                      handleSortChange(header.key);
                    else {
                    }
                  }}
                >
                  {header.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              data.map((single, index) => {
                return (
                  <TableRow
                    key={single._id}
                    sx={{
                      borderBottom: 'none',
                      backgroundColor: white,
                    }}
                  >
                    {headers.map((header, ind) => (
                      <StyledTableCell
                        key={header.key + index}
                        align={header.align ? header.align : 'left'}
                        sx={{
                          minWidth: header.minWidth ? header.minWidth : 'auto',
                        }}
                      >
                        {header.key === 'title' || header.key === 'name' ? (
                          <div style={styles.title}  onClick={(e) => header.key === 'title' && header.title === 'Company Name' && handleManage(e, single)} >
                            <img
                              src={single?.logo ? single.logo : AvatarImage}
                              style={header.title === 'Company Name' && header.key === 'title' ? styles.companyLogoBig : styles.logo}
                              alt="Logo"
                            />
                            <Box sx={styles.nameBox}>
                              <Typography sx={styles.nameBoxName}>
                                {single[header.key]}
                              </Typography>
                              <Typography sx={styles.nameBoxWebsite}>
                                {single['website']}
                              </Typography>
                            </Box>
                          </div>
                        ) : header.key === 'totalUsers' ? (
                          <Box>
                            <Typography variant="body1" sx={{ mb: '8px' }}>
                              {single?.totalUsers}
                            </Typography>
                          </Box>
                        ) : header.key === 'mainContactName' ? (
                          <Box>
                            <Typography variant="body1" sx={{ mb: '8px' }}>
                              {single?.mainContactName || ''}
                            </Typography>
                          </Box>
                        ) : header.key === 'username' ? (
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: 'SoehneBold' }}
                            >
                              {single?.username || ''}
                              {single?.disabled && (
                                <span
                                  style={{
                                    backgroundColor: '#F5F5F5',
                                    border: '1px solid #EEEEEE',
                                    borderRadius: '71px',
                                    color: black,
                                    fontSize: '14px',
                                    padding: '4px',
                                    marginLeft: '8px',
                                  }}
                                >
                                  Disabled
                                </span>
                              )}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: '8px' }}>
                              {single?.email || ''}
                            </Typography>
                          </Box>
                        ) : header.key === 'companyName' ? (
                          <div style={styles.title} onClick={ (e) => handleManage(e, single)}>
                            <img
                              src={single?.logo ? single.logo : AvatarImage}
                              style={styles.companyLogo}
                              alt="Logo"
                            />
                            <Typography>{single[header.key]}</Typography>
                          </div>
                        ) : header.key === 'teamname' ? (
                          <Box>{single[header.key]}</Box>
                        ) : header.key === 'paid' ? (
                          <Box>{single[header.key] ? "Paid": "Free"}</Box>
                        ) : header.key === 'tokenToSend' ? (
                          <Box style={{cursor: 'pointer'}} onClick={() => handleCopy(single[header.key])}>Click to copy token</Box>
                        ) : header.key === 'actions' ? (
                          <Box>
                            <Typography
                              variant="body1"
                              sx={styles.manage}
                              onClick={(e) => handleManage(e, single)}
                            >
                              Manage
                            </Typography>
                          </Box>
                        ): header.key === 'resendInvite' ? (
                          <Box>
                            <Typography
                              variant="body1"
                              sx={styles.manage}
                              onClick={(e) => handleResendInvite(e, single)}
                            >
                              Resend invite
                            </Typography>
                          </Box>
                        ) : (
                          <Typography
                            sx={{ fontWeight: 400, fontSize: '16px' }}
                          >
                            {single[header.key] ?? '-'}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <>
                {[...Array(5).keys()].map((item) => {
                  return (
                    <TableRow key={item}>
                      {headers.map((item1, index) => (
                        <StyledTableCell key={index}>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>

      {!loading && data.length < 1 && (
        <Box sx={styles.noDataBox}>
          <img src={NoDataImage} alt="No Data Found" />
          <Typography variant="body1" sx={styles.noDataText}>
            No data found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AppTable;

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: { fontSize: '16px', color: text },
  [`&.${tableCellClasses.body}`]: { fontSize: '16px', border: '0px' },
});

const StyledTableContainer = styled(TableContainer)({
  [`&.${tableContainerClasses.root}`]: { borderRadius: '8px 8px 8px 8px' },
});

const StyledTable = styled(Table)({
  [`&.${tableClasses.root}`]: { background: 'white' },
});

const styles = {
  noDataText: { fontFamily: 'SoehneBold', textAlign: 'center' },
  noDataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '64px',
  },
  more: {
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '10px',
    display: 'flex',
    textAlign: 'center',
  },
  avatar: {
    marginRight: '-5px',
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
  pagination_row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  paginationDivider: { mt: '20px', border: '1px solid #E9ECEC' },
  paginationBox: { display: 'flex', justifyContent: 'center', mt: '20px' },
  pagination: {
    '.Mui-selected': {
      backgroundColor: `${primary} !important`,
      color: 'white',
    },
  },
  title: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
  logo: {
    marginRight: '12px',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
  },
  companyLogo: {
    marginRight: '12px',
    width: '24px',
    height: '24px',
    borderRadius: '6px',
  },
  companyLogoBig: {
    marginRight: '12px',
    width: '56px',
    height: '56px',
    borderRadius: '6px',
  },
  nameBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '52px',
  },
  nameBoxName: {
    fontWeight: 700,
    fontFamily: 'SoehneBold',
    fontSize: '16px',
  },
  nameBoxWebsite: {
    fontWeight: 400,
    fontFamily: `"Copyright Klim Type Foundry", sans-serif`,
    fontSize: '14px',
    marginTop: '17px',
  },
  manage: {
    fontWeight: 400,
    fontFamily: `"Copyright Klim Type Foundry", sans-serif`,
    fontSize: '14px',
    color: primary,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
};

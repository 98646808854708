import * as React from "react";
import {
  Dialog,
  Toolbar,
  AppBar,
  Input,
  InputAdornment,
  IconButton,
  Slide,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import cssStyles from "../../styles/dashboardHeader.module.css";

const styles = {
  appBar: { position: "relative", py: "10px" },
  input: { p: "10px", mx: "10px", bgcolor: "white", borderRadius: "32px" },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchModal = ({ open, handleClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={cssStyles.search_modal}
    >
      <AppBar sx={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={handleClose}
          >
            <ArrowBackIcon />
          </IconButton>
          <Input
            id="search"
            autoFocus
            fullWidth
            disableUnderline
            sx={styles.input}
            placeholder="Search Plan"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Toolbar>
      </AppBar>
      {/* <List>
        <ListItem button>
          <ListItemText primary="Phone ringtone" secondary="Titania" />
        </ListItem>
        <Divider />
      </List> */}
    </Dialog>
  );
};

export default SearchModal;

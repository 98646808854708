import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InputWithLabel from '../common/InputWithLabel';
import {
  black,
  mercury,
  shark,
  warning,
  white,
  wildSand,
} from '../../constants/colors';

export const NewAdminModal = ({
  open,
  handleClose,
  inviteAdmin,
  newAdmin,
  handleInviteChange,
  type,
  handleDelete,
  updateUser,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.paper }}>
      <Typography variant="h5" sx={styles.heading}>
        {type === 'manage' ? 'Edit admin' : 'Add new admin'}
        <HighlightOffOutlinedIcon sx={styles.closeIcon} onClick={handleClose} />
      </Typography>

      <Box sx={styles.name}>
        <InputWithLabel
          label="First name"
          name="firstName"
          value={newAdmin?.firstName}
          placeholder="John"
          styles={{ marginRight: '24px' }}
          handleChange={(e) => handleInviteChange(e, 'firstName')}
        />

        <InputWithLabel
          label="Last name"
          name="lastName"
          value={newAdmin?.lastName}
          placeholder="Doe"
          handleChange={(e) => handleInviteChange(e, 'lastName')}
        />
      </Box>
      <Box sx={styles.name}>
        <InputWithLabel
          label="Email"
          name="email"
          value={newAdmin?.email}
          placeholder="john@doe.com"
          handleChange={(e) => handleInviteChange(e, 'email')}
        />
      </Box>
      <Box
        style={{
          ...styles.modalBtn,
          justifyContent: type === 'manage' ? 'space-between' : 'flex-end',
        }}
      >
        {type === 'manage' && (
          <Typography
            variant="contained"
            sx={styles.delete}
            onClick={handleDelete}
          >
            Delete admin
          </Typography>
        )}
        <Box sx={styles.btns}>
          <Button variant="contained" sx={styles.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={styles.template}
            onClick={() => (type === 'manage' ? updateUser() : inviteAdmin())}
          >
            {type === 'manage' ? 'Update' : 'Invite'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const styles = {
  paper: {
    borderRadius: '8px',
    padding: '32px',
    width: '458px',
  },
  heading: {
    color: black,
    fontFamily: 'SoehneBold',
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: shark,
    fontSize: '30px',
    background: wildSand,
    borderRadius: '50%',
    padding: '5px',
    cursor: 'pointer',
  },
  modalBtn: {
    display: 'flex',
    width: '100%',
    marginTop: '40px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  cancel: {
    color: black,
    width: '81px',
    height: '40px',
    background: mercury,
    borderRadius: '100px',
    boxShadow: 'none',
    border: `1px solid ${mercury}`,
    fontSize: '16px',
    '&:hover': { background: mercury },
  },
  template: {
    color: 'white',
    width: '138px',
    height: '40px',
    borderRadius: '100px',
    boxShadow: 'none',
    fontSize: '16px',
  },
  delete: {
    color: warning,
    padding: '8px 16px',
    borderRadius: '100px',
    fontFamily: 'SoehneBold',
    boxShadow: 'none',
    background: white,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    '&:hover': { background: 'none' },
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0px',
  },
};
